export const orderInfo = {
  order: "Order",
  orderRequest: "Order request",
  orderCode: "Order code",
  noOrders: "There are no orders in the list, you can request a new order directly from the cart.",
  isExpired: "The order expired on",
  notValid: "It is no longer valid ",
  expireIn: "Attention: the order will expire on",
  createdOn: "Creation date",
  deliveryDate: "Delivery date",
  acceptMultipleShipping: "Multiple Shipping",
  genericNote: "Generic Note",
  unconditionalDiscount: "Unconditional Discount",
  transportReason: "Transport reason",
  port: "Port",
  shippingNote: "Shipping note",
  statusFilter: "Status filter",
    totalOrder: "Order total",
    totalWeight: "Total weight",
  text: {
     ACCEPTED: "Your order has been accepted",
     PENDING: "The order will be evaluated as soon as possible. ",
     EXPIRED: "It is possible to request a new order within 15 days from the deadline, after that period it will be eliminated.",
     REJECTED: "The order has been rejected"
  },
  requestStatus: {
     PENDING: "Under review",
     ACCEPTED: "Accepted",
     REJECTED: "Rejected",
     CLOSED: "Closed",
     EXPIRED: "Expired"
  },
  orderStatus: {
     I: "Undertaken ",
     A: "Cancelled",
     V: "Validated",
     C: "Confirmed",
     E: "Shipped",
     F: "Invoiced"
  },
  item: {
     quantityOrdered: "Ordered quantity",
     quantityConfirmed: "Confirmed quantity",
     quantityProcessed: "Shipped quantity",
     discount: "Discount",
     rowWeight: "Row weight",
      rowTotal: "Totale"
  }
}