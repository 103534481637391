import postalCodes from "postal-codes-js";
import { isValidNumberForRegion } from "libphonenumber-js";
//import { stdnum } from 'stdnum';

const MAX_LENGTH_USERNAME = 50;
const MAX_LENGTH_FIRSTNAME = 100;
const MAX_LENGTH_LASTNAME = 100;
const MAX_LENGTH_BUSINESS_NAME = 150;
const MAX_LENGTH_SHIPPINGADDRESSES = 500;
const MAX_LENGTH_ZIP_OR_POSTAL_CODE = 10;
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const EMAIL_LOGIN_REGEX = /^(|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))+([\+.]((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))+)*$/;
const WEBSITE_REGEX_RFC2396 = /^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/;
const OLD_WEBSITE_REGEX = /^(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)$/;
const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,50}$/;
const SDI_REGEX = /^[A-Z0-9]{7}$/;
const VATNUBER_REGEX = /^[A-Z0-9]{5,18}$/;
const IBAN_REGEX = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{11,30}$/;
const CODE_CUSTOMER_REGEX = /^([0-9]{1}|[1-9][0-9]{1,6})$/;
const CUSTOMER_ORDER_CODE_REGEX = /^[ A-Za-z0-9_@./#&+-\s]*$/;

//TODO split in multiple files
class RequirementObject {
  required = Boolean();
  maxlenght;
  patternTest;
  validation;
  validationMethod;
  constructor(required, maxlenght, patternTest, validationMethod) {
    this.required = required || false;
    this.maxlenght = maxlenght || null;
    this.patternTest = patternTest || null;
    this.validation = validationMethod ? true : false || false;
    this.validationMethod = validationMethod
      ? validationMethod()
      : null || null;
  }
}

class Requirements {
  //TODO? this can be modulate?
  forms = {
    login: {
      //TODO add regex if in db there's users with valid psw and usn
      username: new RequirementObject(true, MAX_LENGTH_USERNAME, EMAIL_LOGIN_REGEX),
      password: new RequirementObject(true, null),
    },
    forgotPassword: {
      username: new RequirementObject(true, MAX_LENGTH_USERNAME, EMAIL_REGEX),
    },
    newPassword: {
      password: new RequirementObject(true, null, PASSWORD_REGEX),
      rerepeatPassword: new RequirementObject(
        true,
        null,
        PASSWORD_REGEX,
        this.sameAs
      ),
    },
    /*since formModel and formRequirement share the same property name 
        TODO? what if there is an interface */
    formUserPrivate: {
      firstname: new RequirementObject(true, MAX_LENGTH_FIRSTNAME),
      lastname: new RequirementObject(true, MAX_LENGTH_LASTNAME),
      username: new RequirementObject(
        true,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX,
        this.sameAs
      ),
      confirmusername: new RequirementObject(true, null, null, this.sameAs),
      telephone: new RequirementObject(false, null, null, this.validTel),
      country: new RequirementObject(true),
      zipCode: new RequirementObject(true, MAX_LENGTH_ZIP_OR_POSTAL_CODE),
      interested: new RequirementObject(true),
      privacyAccepted: new RequirementObject(true),
    },
    formUserCompany: {
      firstname: new RequirementObject(true, MAX_LENGTH_FIRSTNAME),
      lastname: new RequirementObject(true, MAX_LENGTH_LASTNAME),
      username: new RequirementObject(true, MAX_LENGTH_USERNAME, EMAIL_REGEX),
      confirmusername: new RequirementObject(true, null, null, this.sameAs),
      telephone: new RequirementObject(true, null, null, this.validTel),
      country: new RequirementObject(true),
      zipCode: new RequirementObject(true, MAX_LENGTH_ZIP_OR_POSTAL_CODE),
      privacyAccepted: new RequirementObject(true),
      businessName: new RequirementObject(true, MAX_LENGTH_BUSINESS_NAME),
      businessWebsite: new RequirementObject(true, null, OLD_WEBSITE_REGEX),
      //businessWebsite: new RequirementObject(true,null,OLD_WEBSITE_REGEX,this.validateUrl),
      businessDepartment: new RequirementObject(true),
      businessType: new RequirementObject(true),
      businessRole: new RequirementObject(true),
    },
    Anagraphics: {
      businessName: new RequirementObject(true),
      vatNumber: new RequirementObject(true, null, VATNUBER_REGEX),
      sdiCode: new RequirementObject(false, null, SDI_REGEX),
      pec: new RequirementObject(false, null, EMAIL_REGEX),
    },
    AnagraphicsIT: {
      businessName: new RequirementObject(true),
      vatNumber: new RequirementObject(true),
      sdiCode: new RequirementObject(true, null, SDI_REGEX),
      pec: new RequirementObject(true, null, EMAIL_REGEX),
    },
    Addresses: {
      businessAddress: {
        streetAddress1: new RequirementObject(true),
        streetAddress2: new RequirementObject(),
        country: new RequirementObject(true),
        city: new RequirementObject(true),
        zipOrPostalCode: new RequirementObject(
          true,
          MAX_LENGTH_ZIP_OR_POSTAL_CODE
          ),
        province: new RequirementObject(),
        notes: new RequirementObject(),
      },
      legalAddress: {
        streetAddress1: new RequirementObject(true),
        streetAddress2: new RequirementObject(),
        country: new RequirementObject(true),
        city: new RequirementObject(true),
        zipOrPostalCode: new RequirementObject(
          true,
          MAX_LENGTH_ZIP_OR_POSTAL_CODE
        ),
        province: new RequirementObject(),
        notes: new RequirementObject(),
      },
      shippingAddresses: {
        ...new RequirementObject(false, MAX_LENGTH_SHIPPINGADDRESSES),
        ...{
          objectElement: {
            businessName: new RequirementObject(true),
            streetAddress1: new RequirementObject(true),
            streetAddress2: new RequirementObject(),
            country: new RequirementObject(true),
            city: new RequirementObject(true),
            zipOrPostalCode: new RequirementObject(
              true,
              MAX_LENGTH_ZIP_OR_POSTAL_CODE
            ),
            province: new RequirementObject(),
            notes: new RequirementObject(),
          },
        },
      },
    },
    Contacts: {
      purchasingContact: {
        gender: new RequirementObject(true),
        firstName: new RequirementObject(true, MAX_LENGTH_FIRSTNAME),
        lastName: new RequirementObject(true, MAX_LENGTH_LASTNAME),
        role: new RequirementObject(true),
        email: new RequirementObject(true, MAX_LENGTH_USERNAME, EMAIL_REGEX),
        phoneNumber: new RequirementObject(true, null, null, this.validTel),
      },
      administrativeContact: {
        gender: new RequirementObject(true),
        firstName: new RequirementObject(true, MAX_LENGTH_FIRSTNAME),
        lastName: new RequirementObject(true, MAX_LENGTH_LASTNAME),
        role: new RequirementObject(true),
        email: new RequirementObject(true, MAX_LENGTH_USERNAME, EMAIL_REGEX),
        phoneNumber: new RequirementObject(true, null, null, this.validTel),
      },
      billingEmail1: new RequirementObject(
        true,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      billingEmail2: new RequirementObject(
        false,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      transportDocumentEmail1: new RequirementObject(
        true,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      transportDocumentEmail2: new RequirementObject(
        false,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      msdsEmail1: new RequirementObject(true, MAX_LENGTH_USERNAME, EMAIL_REGEX),
      msdsEmail2: new RequirementObject(
        false,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      testSheetsEmail1: new RequirementObject(
        true,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      testSheetsEmail2: new RequirementObject(
        false,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
    },
    ContactsAgent: {
      purchasingContact: {
        gender: new RequirementObject(true),
        firstName: new RequirementObject(true, MAX_LENGTH_FIRSTNAME),
        lastName: new RequirementObject(true, MAX_LENGTH_LASTNAME),
        role: new RequirementObject(true),
        email: new RequirementObject(true, MAX_LENGTH_USERNAME, EMAIL_REGEX),
        phoneNumber: new RequirementObject(true, null, null, this.validTel),
      },
      administrativeContact: {
        gender: new RequirementObject(true),
        firstName: new RequirementObject(true, MAX_LENGTH_FIRSTNAME),
        lastName: new RequirementObject(true, MAX_LENGTH_LASTNAME),
        role: new RequirementObject(true),
        email: new RequirementObject(true, MAX_LENGTH_USERNAME, EMAIL_REGEX),
        phoneNumber: new RequirementObject(true, null, null, this.validTel),
      },
      additionalContacts: {
        ...new RequirementObject(false, MAX_LENGTH_SHIPPINGADDRESSES),
        ...{
          objectElement: {
            gender: new RequirementObject(true),
            firstName: new RequirementObject(true, MAX_LENGTH_FIRSTNAME),
            lastName: new RequirementObject(true, MAX_LENGTH_LASTNAME),
            role: new RequirementObject(true),
            email: new RequirementObject(
              true,
              MAX_LENGTH_USERNAME,
              EMAIL_REGEX
            ),
            phoneNumber: new RequirementObject(true, null, null, this.validTel),
          },
        },
      },
      billingEmail1: new RequirementObject(
        true,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      billingEmail2: new RequirementObject(
        false,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      transportDocumentEmail1: new RequirementObject(
        true,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      transportDocumentEmail2: new RequirementObject(
        false,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      msdsEmail1: new RequirementObject(true, MAX_LENGTH_USERNAME, EMAIL_REGEX),
      msdsEmail2: new RequirementObject(
        false,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      testSheetsEmail1: new RequirementObject(
        true,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      testSheetsEmail2: new RequirementObject(
        false,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      tntNextRequired: new RequirementObject(),
    },
    BankInformation: {
      bankName: new RequirementObject(true),
      iban: new RequirementObject(true, null, IBAN_REGEX),
    },
    CompanyPerspective: {
      marketSegmentation1: new RequirementObject(),
      marketSegmentation2: new RequirementObject(),
      marketSegmentation3: new RequirementObject(),
      marketSegmentation4: new RequirementObject(),
      marketCompetitor1: new RequirementObject(true),
      marketCompetitor2: new RequirementObject(),
      totalConsumption: new RequirementObject(),
      alceaPotential: new RequirementObject(),
      isRetailerOfMetal: new RequirementObject(),
      isRetailerOfWood: new RequirementObject(),
      isRetailerOfConstruction: new RequirementObject(),
      isRetailerOfBodywork: new RequirementObject(),
      isRetailerOfOwnership: new RequirementObject(),
      retailerShopWindows: new RequirementObject(),
      retailerSurface: new RequirementObject(),
    },
    ConfirmRequest: {
      businessName: new RequirementObject(true),
      vatNumber: new RequirementObject(true, null, VATNUBER_REGEX),
      sdiCode: new RequirementObject(false, null, SDI_REGEX),
      pec: new RequirementObject(false, null, EMAIL_REGEX),
      businessAddress: {
        streetAddress1: new RequirementObject(true),
        streetAddress2: new RequirementObject(),
        country: new RequirementObject(true),
        city: new RequirementObject(true),
        zipOrPostalCode: new RequirementObject(
          true,
          MAX_LENGTH_ZIP_OR_POSTAL_CODE
        ),
        province: new RequirementObject(),
        notes: new RequirementObject(),
      },
      legalAddress: {
        streetAddress1: new RequirementObject(true),
        streetAddress2: new RequirementObject(),
        country: new RequirementObject(true),
        city: new RequirementObject(true),
        zipOrPostalCode: new RequirementObject(
          true,
          MAX_LENGTH_ZIP_OR_POSTAL_CODE
        ),
        province: new RequirementObject(),
        notes: new RequirementObject(),
      },
      shippingAddresses: {
        ...new RequirementObject(false, MAX_LENGTH_SHIPPINGADDRESSES),
        ...{
          objectElement: {
            businessName: new RequirementObject(true),
            streetAddress1: new RequirementObject(true),
            streetAddress2: new RequirementObject(),
            country: new RequirementObject(true),
            city: new RequirementObject(true),
            zipOrPostalCode: new RequirementObject(
              true,
              MAX_LENGTH_ZIP_OR_POSTAL_CODE
            ),
            province: new RequirementObject(),
            notes: new RequirementObject(),
          },
        },
      },
      purchasingContact: {
        gender: new RequirementObject(true),
        firstName: new RequirementObject(true, MAX_LENGTH_FIRSTNAME),
        lastName: new RequirementObject(true, MAX_LENGTH_LASTNAME),
        role: new RequirementObject(true),
        email: new RequirementObject(true, MAX_LENGTH_USERNAME, EMAIL_REGEX),
        phoneNumber: new RequirementObject(true, null, null, this.validTel),
      },
      administrativeContact: {
        gender: new RequirementObject(true),
        firstName: new RequirementObject(true, MAX_LENGTH_FIRSTNAME),
        lastName: new RequirementObject(true, MAX_LENGTH_LASTNAME),
        role: new RequirementObject(true),
        email: new RequirementObject(true, MAX_LENGTH_USERNAME, EMAIL_REGEX),
        phoneNumber: new RequirementObject(true, null, null, this.validTel),
      },
      billingEmail1: new RequirementObject(
        true,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      billingEmail2: new RequirementObject(
        false,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      transportDocumentEmail1: new RequirementObject(
        true,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      transportDocumentEmail2: new RequirementObject(
        false,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      msdsEmail1: new RequirementObject(true, MAX_LENGTH_USERNAME, EMAIL_REGEX),
      msdsEmail2: new RequirementObject(
        false,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      testSheetsEmail1: new RequirementObject(
        true,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      testSheetsEmail2: new RequirementObject(
        false,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      bankName: new RequirementObject(true),
      iban: new RequirementObject(true, null, IBAN_REGEX),
    },
    ConfirmRequestAgent: {
      businessName: new RequirementObject(true),
      vatNumber: new RequirementObject(true, null, VATNUBER_REGEX),
      sdiCode: new RequirementObject(false, null, SDI_REGEX),
      pec: new RequirementObject(false, null, EMAIL_REGEX),
      marketSegmentation1: new RequirementObject(),
      marketSegmentation2: new RequirementObject(),
      marketSegmentation3: new RequirementObject(),
      marketSegmentation4: new RequirementObject(),
      marketCompetitor1: new RequirementObject(true),
      marketCompetitor2: new RequirementObject(),
      totalConsumption: new RequirementObject(),
      alceaPotential: new RequirementObject(),
      isRetailerOfMetal: new RequirementObject(),
      isRetailerOfWood: new RequirementObject(),
      isRetailerOfConstruction: new RequirementObject(),
      isRetailerOfBodywork: new RequirementObject(),
      isRetailerOfOwnership: new RequirementObject(),
      retailerShopWindows: new RequirementObject(),
      retailerSurface: new RequirementObject(),
      businessAddress: {
        streetAddress1: new RequirementObject(true),
        streetAddress2: new RequirementObject(),
        country: new RequirementObject(true),
        city: new RequirementObject(true),
        zipOrPostalCode: new RequirementObject(
          true,
          MAX_LENGTH_ZIP_OR_POSTAL_CODE
        ),
        province: new RequirementObject(),
        notes: new RequirementObject(),
      },
      legalAddress: {
        streetAddress1: new RequirementObject(true),
        streetAddress2: new RequirementObject(),
        country: new RequirementObject(true),
        city: new RequirementObject(true),
        zipOrPostalCode: new RequirementObject(
          true,
          MAX_LENGTH_ZIP_OR_POSTAL_CODE
        ),
        province: new RequirementObject(),
        notes: new RequirementObject(),
      },
      shippingAddresses: {
        ...new RequirementObject(false, MAX_LENGTH_SHIPPINGADDRESSES),
        ...{
          objectElement: {
            businessName: new RequirementObject(true),
            streetAddress1: new RequirementObject(true),
            streetAddress2: new RequirementObject(),
            country: new RequirementObject(true),
            city: new RequirementObject(true),
            zipOrPostalCode: new RequirementObject(
              true,
              null,
              null,
              this.validateZipCode
            ),
            province: new RequirementObject(),
            notes: new RequirementObject(),
          },
        },
      },
      purchasingContact: {
        gender: new RequirementObject(true),
        firstName: new RequirementObject(true, MAX_LENGTH_FIRSTNAME),
        lastName: new RequirementObject(true, MAX_LENGTH_LASTNAME),
        role: new RequirementObject(true),
        email: new RequirementObject(true, MAX_LENGTH_USERNAME, EMAIL_REGEX),
        phoneNumber: new RequirementObject(true, null, null, this.validTel),
      },
      administrativeContact: {
        gender: new RequirementObject(true),
        firstName: new RequirementObject(true, MAX_LENGTH_FIRSTNAME),
        lastName: new RequirementObject(true, MAX_LENGTH_LASTNAME),
        role: new RequirementObject(true),
        email: new RequirementObject(true, MAX_LENGTH_USERNAME, EMAIL_REGEX),
        phoneNumber: new RequirementObject(true, null, null, this.validTel),
      },
      additionalContacts: {
        ...new RequirementObject(false, MAX_LENGTH_SHIPPINGADDRESSES),
        ...{
          objectElement: {
            gender: new RequirementObject(true),
            firstName: new RequirementObject(true, MAX_LENGTH_FIRSTNAME),
            lastName: new RequirementObject(true, MAX_LENGTH_LASTNAME),
            role: new RequirementObject(true),
            email: new RequirementObject(
              true,
              MAX_LENGTH_USERNAME,
              EMAIL_REGEX
            ),
            phoneNumber: new RequirementObject(true, null, null, this.validTel),
          },
        },
      },
      billingEmail1: new RequirementObject(
        true,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      billingEmail2: new RequirementObject(
        false,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      transportDocumentEmail1: new RequirementObject(
        true,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      transportDocumentEmail2: new RequirementObject(
        false,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      msdsEmail1: new RequirementObject(true, MAX_LENGTH_USERNAME, EMAIL_REGEX),
      msdsEmail2: new RequirementObject(
        false,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      testSheetsEmail1: new RequirementObject(
        true,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      testSheetsEmail2: new RequirementObject(
        false,
        MAX_LENGTH_USERNAME,
        EMAIL_REGEX
      ),
      tntNextRequired: new RequirementObject(),
    },
    association: {
      customerCode: new RequirementObject(true, null, CODE_CUSTOMER_REGEX),
    },
    invitation: {
      inviteeEmail: new RequirementObject(true, null, EMAIL_REGEX),
      inviteeFirstName: new RequirementObject(true),
      inviteeLastName: new RequirementObject(true),
    },
    formCheckout:{
      address:new RequirementObject(true),
      deliveryDateRequested:new RequirementObject(true),
      customerOrderCode: new RequirementObject(false, 8, CUSTOMER_ORDER_CODE_REGEX),
    },
    contactArea: {
      name: new RequirementObject(true, MAX_LENGTH_USERNAME),
      email: new RequirementObject(true, MAX_LENGTH_USERNAME, EMAIL_REGEX),
      request: new RequirementObject(true),
      requestType: new RequirementObject(true)
    }
  };

  sameAs() {
    return function (value, comparevalue) {
      return value == comparevalue;
    };
  }
  validateZipCode() {
    return function (zipCode, country) {
      return postalCodes.validate(country, zipCode) == true ? true : false;
    };
  }

  validTel() {
    return function (telForm, telObject) {
      if (telForm) {
        console.log(
          "Validation Phone",
          isValidNumberForRegion(telObject.number, telObject.telephonePrefix)
        );
        return isValidNumberForRegion(
          telObject.number,
          telObject.telephonePrefix
        ) == true
          ? true
          : false;
      }
      return true;
    };
  }
  validateUrl() {
    return function (ecodeduri, url) {
      try {
        ecodeduri == new URL(url).origin;
        return true;
      } catch (e) {
        return false;
      }
    };
  }
}

// console.log(stdnum.BR.cpf.name); // 'Brazilian National Identifier',
// console.log(stdnum.BR.cpf.localName); // 'Cadastro de Pessoas Físicas',
// console.log(stdnum.BR.cpf.abbreviation); // 'CPF',

// const {
//     isValid, // is false
//     error, // InvalidLength: The number has an invalid length...
// } = stdnum.BR.cpf.validate('xyzzy');

// const {
//     isValid, // true
//     compact, // '39053344705'
//     isIndividual, // true
//     isEntity, // false
// } = stdnum.BR.cpf.validate("390.533.447-05");
export default new Requirements();
