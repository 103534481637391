export const languages = {
  lang: "Lingua",
  IT: "Italiano",
  EN: "Inglese",
  DE: "Tedesco",
  ES: "Spagnolo",
  FR: "Francese",
  PL: "Polacco",
  RU: "Russo",
  it: "Italiano",
  en: "Inglese",
  de: "Tedesco",
  es: "Spagnolo",
  fr: "Francese",
  pl: "Polacco",
  ru: "Russo",
};
