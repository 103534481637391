<template>
  <div>
    <label for="email1"> {{ $t("formCustomer." + emailtype) }}</label>
    <div class="row align-items-top">
      <b-form-group
        class="col-md-5 col-9 "
        :invalid-feedback="validationError(emailtype + 'Email1')"
      >
        <b-form-input
          type="text"
          :id="this.emailtype + 'email1'"
          :name="this.emailtype + 'email1'"
          v-model="assignment.email1"
          @input="handleEmail1"
          :placeholder="$t('formCustomer.' + emailtype + 'Recipient') + ' 1 *'"
          :disabled="assignment.assigned ? true : false"
          :state="validationState(emailtype + 'Email1')"
        ></b-form-input>
      </b-form-group>
      <b-button v-if="buttonValue == '+'" class="col-auto mx-3 mb-auto" @click="toggle">{{
        buttonValue
      }}</b-button>
      <b-form-group
        class="col-md-5 col-9"
        :invalid-feedback="validationError(emailtype + 'Email2')"
      >
        <b-form-input
          type="text"
          :id="this.emailtype + 'email2'"
          :name="this.emailtype + 'email2'"
          v-model="assignment.email2"
          @input="handleEmail2"
          :placeholder="$t('formCustomer.' + emailtype + 'Recipient') + ' 2'"
          v-if="buttonValue == '-'"
          :state="validationState(emailtype + 'Email2')"
        ></b-form-input>
      </b-form-group>
      <b-button v-if="buttonValue == '-'" class="col-auto mx-3 mb-auto" @click="toggle">
        {{ buttonValue }}
      </b-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "contactAssignment",
  props: {
    assignment: Object(),
    emailtype: String(),
    form: Object(),
    invalidErrors: Array(),
  },
  data() {
    return {
      buttonValue: "+",
    };
  },
  computed: {
    refEmail() {
      return this.emailtype + "Email1";
    },
  },
  mounted() {},
  methods: {
    //TODO with icon
    handleEmail1(e) {
      this.assignment.email1 = e;
      this.handleInput();
    },
    handleEmail2(e) {
      this.assignment.email2 = e;
      this.handleInput();
    },
    handleInput() {      
      this.form[this.refEmail] = this.assignment.email1;
      this.$emit("input", this.assignment);
     console.debug('BILLINGfromRef', this.form[this.refEmail],'BILLINGprop',this.assignment.email1,'BILLINGform',this.form.billingEmail1)
    },
    toggle() {
      this.buttonValue == "+"
        ? (this.buttonValue = "-")
        : (this.buttonValue = "+");
    },
    validationState(contextRef) {
      return this.invalidErrors.find(
        (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
      )
        ? false
        : null;
    },

    validationError(contextRef) {
      if (this.invalidErrors.length > 0) {
        let find =
          this.invalidErrors.find(
            (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
          ) || null;
        return find ? this.$i18n.t(find.error) : null;
      }
    },
  },
  watch: {},
};
</script>