<template>
  <b-container fluid>
    <!-- private area selector -->
    <b-row class="private-area-selector">
      <b-col cols="12" md="6" :class="leftSelected ? 'private-area-selected': ''">
        <!-- link: user profile -->
        <h3 @click.stop="$router.push('/profile-user')">
          {{ $t("titles.profileUser") }}
        </h3>
      </b-col>

      <b-col cols="12" md="6" v-if="isCustomer || isUserAgentOrCommercial" :class="leftSelected ? '': 'private-area-selected'">
        <!-- link: customer section -->
        <h3 @click.stop="$router.push('/profile-customer')" v-if="isCustomer">
          {{ $t("titles.profileCustomer") }}
        </h3>

        <!-- link: agent section -->
        <h3 @click.stop="$router.push('/profile-agent')" v-if="isUserAgentOrCommercial">
          {{ $t("titles.areaAgent") }}
        </h3>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "PrivateAreaSelector",

  props: ['leftSelected'],

  computed: {
    isCustomer() {
      return this.$store.getters.getUserRole === 'CUSTOMER'
    },

    isUserAgentOrCommercial() {
      return this.$store.getters.getUserRole === "AGENT" || this.$store.getters.getUserRole === "COMMERCIAL"
    },
  },
};
</script>
