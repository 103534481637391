export class Product {
  id = new ProductCode();
  sortOrder;
  productCode;
  allDescriptions = new Array(new Description());
  pdtDesc;
  description;
  brand;
  um;
  inStock;
  qta;
  status;
  unitPrice;
  quantity;
  priceListCode;
  discountPct1;
  discountPct2;
  finalUnitPrice;
  preferred
  buyable;

  findDescriptionPerLang(lang, fallback) {
    let returnDesc;
    lang = lang.toUpperCase();
    fallback = fallback.toUpperCase();
    if (this.allDescriptions.length > 0) {
      const find = this.allDescriptions.find((d) => d.lang == lang);
      let fallbackIT = this.allDescriptions.find((d) => d.lang == "IT")
        ? this.allDescriptions.find((d) => d.lang == "IT")
        : "";
      let findFallback = this.allDescriptions.find((d) => d.lang == fallback)
        ? this.allDescriptions.find((d) => d.lang == fallback)
        : fallbackIT;
      returnDesc =
        find && find.colorDesc
          ? this.buildDesc(find)
          : this.buildDesc(findFallback);
    } else {
      returnDesc = pdtDesc;
    }
    return returnDesc ? returnDesc : "";
  }

  buildDesc(desc) {
    return desc.shortDesc
      ? desc.colorDesc + " - " + desc.shortDesc
      : desc.colorDesc;
  }
}

export function getProductFromDto(dto) {
  let product = new Product();
  product.id = dto.id ? dto.id : null;
  product.productCode = dto.id ? dto.id.serial + "-" + dto.id.color + "-" + dto.id.pkg : null;
  product.brand = dto.brand ? dto.brand : null;
  product.um = dto.um;
  product.qta = dto.qta;
  product.qtyPkgMultiplier = dto.qtyPkgMultiplier;
  product.status = ['A', 'E'].includes(dto.tsc) ? "REMOVED" : (!dto.buyable ? "UNAVAILABLE" : "AVAILABLE");
  product.inStock = !['A', 'E', ' '].includes(dto.tsc);
  product.allDescriptions = getDescriptionsPerLang(dto);
  product.preferred = dto.preferred ? dto.preferred : false;
  product.pdtDesc = dto.pdtDesc;
  return product;
}


export function getOrderedProductFromDto(dto) {
  let product = new Product();
  product.id = dto.id ? dto.id : null;
  product.productCode = dto.id ? dto.id.serial + "-" + dto.id.color + "-" + dto.id.pkg : null;
  product.brand = dto.brand ? dto.brand : null;
  product.um = dto.um;
  product.qta = dto.qta;
  product.qtyPkgMultiplier = dto.qtyPkgMultiplier;
  product.status = ['A', 'E'].includes(dto.tsc) ? "REMOVED" : (!dto.buyable ? "UNAVAILABLE" : "AVAILABLE");
  product.inStock = !['A', 'E', ' '].includes(dto.tsc);
  product.allDescriptions = getDescriptionsPerLang(dto);
  product.preferred = dto.preferred ? dto.preferred : false;
  if(dto.pricesAndDiscounts) {
    product.agentCommissionPct = dto.pricesAndDiscounts.agentCommissionPct;
    product.unitPrice = dto.pricesAndDiscounts.unitPrice;
    product.priceListCode = dto.pricesAndDiscounts.priceListCode;
    product.discountPct1 = dto.pricesAndDiscounts.discountPct1 || 0;
    product.discountPct2 = dto.pricesAndDiscounts.discountPct2 || 0;
    product.finalUnitPrice = dto.pricesAndDiscounts.finalUnitPrice;
  }
  return product;
}

export function getDescriptionsPerLang(dto) {
  let descriptions = new Array();
  let longDescrsiptions = Object();
  let ColorDescriptions = Object();
  Object.keys(dto)
    .filter((key) => key.includes("LongDescr"))
    .forEach((el) => {
      longDescrsiptions[el.replace("LongDescr", "")] = dto[el];
    });
  Object.keys(dto)
    .filter((key) => key.includes("ColorDesc"))
    .forEach((el) => {
      ColorDescriptions[el.replace("ColorDesc", "")] = dto[el];
    });

  Object.keys(dto)
    .filter((key) => key.includes("ShortDesc"))
    .forEach((element) => {
      let desc = new Description();
      desc.shortDesc = dto[element];
      let fieldName = element.replace("ShortDesc", "");
      desc.lang = fieldName.toUpperCase();
      desc.longDesc = longDescrsiptions[fieldName];
      desc.colorDesc = ColorDescriptions[fieldName];
      descriptions.push(desc);
    });
  return descriptions;
}

export function getProductCartFromDto(dto) {
  let product = getProductFromDto(dto);
  product.sortOrder = dto.sortOrder;
  product.id = dto.productCode ? dto.productCode : product.id;
  product.productCode = dto.productCode ? dto.productCode.serial + "-" + dto.productCode.color + "-" + dto.productCode.pkg : product.productCode;
  product.quantity = dto.qtyPkgMultiplier ? dto.qtyPkgMultiplier * dto.quantity : dto.quantity;
  product.um = dto.um;
  product.tsc = dto.tsc;
  product.plo = dto.plo;
  product.unitQty = dto.qta;
  product.qtyPkgMultiplier = dto.qtyPkgMultiplier;
  product.quantity = dto.quantity;
  product.agentCommissionPct = dto.agentCommissionPct;
  product.unitPrice = dto.unitPrice;
  product.priceListCode = dto.priceListCode;
  product.discountPct1 = dto.discountPct1 || 0;
  product.discountPct2 = dto.discountPct2 || 0;
  product.pdtDesc = dto.pdtDesc;
  product.finalUnitPrice = dto.finalUnitPrice ? dto.finalUnitPrice : (dto.unitPrice * (1-product.discountPct1/100) * (1-product.discountPct2/100));
  return product;
}

export function buildProductDto(product) {
  let dto = new ProductDto();
  dto.productCode = product.id;
  dto.quantity = product.quantity ? product.quantity : (product.qtyPkgMultiplier ? product.qtyPkgMultiplier : 1);
  return dto;
}

export function buildProductDtoAddToCart(product) {
  let dto = new ProductDto();
  dto.sortOrder = product.sortOrder;
  dto.productCode = product.id;
  dto.quantity = product.quantity > 0 ? product.quantity : (product.qtyPkgMultiplier ? product.qtyPkgMultiplier : 1);
  dto.pdtDesc = product.pdtDesc;
  return dto;
}

export function buildProductDtoComplete(product) {
  if (product != new ProductDtoComplete(product)) {
    let dto = new ProductDtoComplete();
    dto.productCode = product.id;
    dto.unitPrice = product.unitPrice;
    dto.quantity = product.quantity > 0 ? product.quantity : (product.qtyPkgMultiplier ? product.qtyPkgMultiplier : 1);
    dto.agentCommissionPct = product.agentCommissionPct;
    dto.priceListCode = product.priceListCode;
    dto.discountPct1 = product.discountPct1 || 0;
    dto.discountPct2 = product.discountPct2 || 0;
    dto.finalUnitPrice = product.finalUnitPrice ? product.finalUnitPrice : (product.unitPrice * (1-dto.discountPct1/100) * (1-dto.discountPct2/100));;
    dto.um = product.um;
    dto.unitQty = product.unitQty;
    dto.pdtDesc = product.pdtDesc;
    if (product.allDescriptions) {
      product.allDescriptions.forEach((pr) => {
        let lang = pr.lang.toLowerCase();
        ProductDtoComplete.prototype[lang + "ColorDesc"];
        dto[lang + "ColorDesc"] = pr["colorDesc"];
        ProductDtoComplete.prototype[lang + "ShortDesc"];
        dto[lang + "ShortDesc"] = pr["shortDesc"];
        ProductDtoComplete.prototype[lang + "LongDescr"];
        dto[lang + "LongDescr"] = pr["longDesc"];
      });
    }
    console.log(dto);
    return dto;
  } else {
    return product;
  }
}

export class OrderProduct extends Product {
  id = new ProductCode();
  productCode;
  quantityOrdered;
  quantityConfirmed;
  quantityProcessed;
  unitPrice;
  discountPct1;
  discountPct2;
}

export function getProductOrderFromDto(dto) {
  let product = new OrderProduct();
  product.id = dto.id ? dto.id : null;
  product.productCode = dto.id
    ? dto.id.serial + "-" + dto.id.color + "-" + dto.id.pkg
    : null;
  agentCommissionPct = dto.agentCommissionPct;
  quantityOrdered = dto.quantityOrdered;
  quantityConfirmed = dto.quantityConfirmed;
  quantityProcessed = dto.quantityProcessed;
  unitPrice = dto.unitPrice;
  discountPct1 = dto.discountPct1 || 0;
  discountPct2 = dto.discountPct2 || 0;
  return product;
}

export class ProductDto {
  productCode = new ProductCode();
  //multiplier;
  quantity;
}

export class ProductDtoComplete {
  productCode = new ProductCode();
  unitPrice;
  quantity;
  agentCommissionPct;
  priceListCode;
  discountPct1;
  discountPct2;
  finalUnitPrice;
  um;
  unitQty;
  pdtDesc;
}

export class ProductCode {
  productClass;
  serial;
  color;
  pkg;
}

export class Description {
  lang;
  shortDesc;
  longDesc;
  colorDesc;
}

export default new Product();


//REFERECE dto Product from db
// applications;
// brand;
// cat1;
// cat2;
// cat3;
// cat4;
// cat5;
// catalyst;
// csColorDesc;
// csLongDescr;
// csShortDesc;
// deColorDesc;
// deLongDescr;
// deShortDesc;
// diluent;
// dna;
// drying;
// enColorDesc;
// enLongDescr;
// enShortDesc;
// esColorDesc;
// esLongDescr;
// esShortDesc;
// expires;
// frColorDesc;
// frLongDescr;
// frShortDesc;
// id: {serial: '0000', color: '000', pkg: '00'}
// intest;
// itColorDesc;
// itLongDescr;
// itShortDesc;
// kgEq;
// lat;
// lis5;
// lis6;
// lisF;
// lisL;
// lisR;
// lisT;
// plColorDesc;
// plLongDescr;
// plShortDesc;
// plo;
// productCode: "0000-000-00"
// productVisibility;
// pzs;
// qta;
// ral;
// resakg;
// resalt;
// ruColorDesc;
// ruLongDescr;
// ruShortDesc;
// tsc;
// um;
