// all the values with $ will be overwritten from foreign configuration
const CONFIG = {
  VUE_APP_API_URL: "$VUE_APP_API_URL",
  VUE_APP_I18N_LOCALE: "$VUE_APP_I18N_LOCALE",
  VUE_APP_I18N_FALLBACK_LOCALE: "$VUE_APP_I18N_FALLBACK_LOCALE",
  VUE_APP_DEBOUNCING_TIME: "$VUE_APP_DEBOUNCING_TIME"
};

export default {
  value(key) {
    let value = CONFIG[key];

    // this value wasn't overwritten => get it from process.env
    if (value && value === "$" + key) {
      value = process.env[key];
    }

    return value;
  }
};
