export const orderInfo = {
  order: "Ordine",
  orderRequest: "Richiesta ordine",
  orderCode: "Codice ordine",
  noOrders: "Non sono presenti ordini in lista, puoi richiedere un nuovo ordine direttamente dal carrello.",
  isExpired: "L’ordine è scaduto in data",
  notValid: "non è più valido",
  expireIn: "Attenzione: l’ordine scadrà in data ",
  createdOn: "Data creazione",
  deliveryDate: "Data di consegna",
  acceptMultipleShipping: "Spedizione Frazionabile",
  genericNote: "Note generiche",
  unconditionalDiscount: "Sconto globale",
  agentNote: "Note Commerciale",
  offerNote: "Note Offerta",
  transportReason: "Motivo di trasporto",
  port: "Porto",
  shippingNote: "Note di spedizione",
  statusFilter: "Filtro Stato",
  totalOrder: "Totale ordine",
  totalWeight: "Peso totale",
  text: {
    ACCEPTED: "Il tuo ordine è statto accettato",
    PENDING: "L'ordine verrà valutato appena possibile",
    EXPIRED: "è possibile fare la richiesta per un nuovo ordine entro 15 giorni dalla scadenza, termine nel quale verrà eliminata.",
    REJECTED: "L'ordine è stato respinto"
  },
  requestStatus: {
    PENDING: "In revisione",
    ACCEPTED: "Accettato",
    REJECTED: "Rifiutato",
    CLOSED: "Chiuso",
    EXPIRED: "Scaduto",
  },
  orderStatus: {
    I: "Preso in carico",
    A: "Annullato",
    V: "Validato",
    C: "Confermato",
    E: "Evaso",
    F: "Fatturato"
  },
  item: {
    quantityOrdered: "Qtà ordinata",
    quantityConfirmed: "Qtà confermata",
    quantityProcessed: "Qtà processata",
    discount: "Sconto",
    rowWeight: "Peso",
    rowTotal: "Totale"
  }
}