import http from "@/util/http-common";
import store from "@/store/index";

class ProductService {
  getUrlNoParam() {
    return store.getters.getUserRole == "COMMERCIAL"
      ? "v1/public/commercial" :
      store.getters.getUserRole == "AGENT"
        ? "v1/public/agent"
        : "v1/public/customer";
  }

  getUrl(url, customer) {
    return store.getters.getUserRole == "COMMERCIAL"
      ? "v1/public/commercial" + `${url}?customerCode=${customer}` :
      store.getters.getUserRole == "AGENT"
        ? "v1/public/agent" + `${url}?customerCode=${customer}`
        : "v1/public/customer" + url;
  }

  getOrderedProducts(page, size, sortBy, sortDir, filters) {
    let url = this.getUrlNoParam() + "/customer-products";
    let call = `${url}?customerCode=${filters.customerCode}`;

    let params = {
      size: size,
      page: page - 1,
      sort: sortBy + "," + sortDir,
    };

    filters.serial ? (params["serial"] = filters.serial) : null;
    filters.color ? (params["color"] = filters.color) : null;
    filters.pkg ? (params["pkg"] = filters.pkg) : null;
    filters.description ? (params["description"] = filters.description) : null;
    filters.brand ? (params["brand"] = filters.brand) : null;
    filters.onlyPreferred ? (params["onlyPreferred"] = filters.onlyPreferred) : null;

    console.info("getOrderedProducts call", call, params);
    return http.get(call, { params });
  }

  togglePreference(item) {
    let customer = store.getters.getCustomerCode;
    let body = {
      customerCode: customer,
      productClass: item.id.productClass,
      serial: item.id.serial,
      color: item.id.color,
      pkg: item.id.pkg,
    };
    return http.patch(this.getUrl("/customer-products/preferred"), body);
  }

  getProduct(productCode) {
    return http.get("/products/" + productCode);
  }

  async getProductsCart(customer) {
    return http.get(this.getUrl("/carts", customer));
  }

  async postNewCart(customer, items) {
    let body = { items: items };
    return http.post(this.getUrl("/carts", customer), body);
  }

  async postProductsInCart(customer, items) {
    console.log("ADD", items);
    return http.post(this.getUrl("/carts/items", customer), items);
  }

  async deleteProductsInCart(customer, items) {
    return http.delete(this.getUrl("/carts/items", customer), {
      data: items,
    });
  }

  async updateProductCart(customer, items) {
    let body = { items: items };
    return http.put(this.getUrl("/carts", customer), body);
  }

}

export default new ProductService();
