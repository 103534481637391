<template>
  <div class="row">
    <h3>{{ $t("titles.contacts") }}</h3>
    <div class="col-md-12">
      <div class="row justify-content-between">
        <h4 class="col">{{ $t("formCustomer.purchasingContact") }}</h4>
        <span class="col-auto d-flex align-items-center">
          <b-icon
            class="ml-auto mr-1"
            id="itsMePurchasingContact"
            aria-hidden="true"
            icon="question-circle"
          ></b-icon>
          <b-tooltip
            target="itsMePurchasingContact"
            title="Tooltip title"
            triggers="hover"
            placement="left"
          >
            {{ $t("tooltips.itsMe") }}
          </b-tooltip>
          <b-checkbox
            v-model="form.purchasingContact"
            :value="userContact('purchasingContact')"
            :unchecked-value="purchasingContactCall()"
            @change="getUserContact('purchasingContactIsUser')"
            >{{ $t("labels.itsMe") }}</b-checkbox
          >
        </span>
      </div>

      <card-contact
        class="mb-2"
        :disabled="purchasingContactIsUser"
        id="purchasingContact"
        ref="purchasingContact"
        :contact="form.purchasingContact"
        :invalidErrors="
          invalidErrors.purchasingContact
            ? invalidErrors.purchasingContact.invalid
            : []
        "
        :assignmentValue="'purchasingContact'"
        :form="form"
        :billing.sync="billing"
        :msds.sync="msds"
        :testSheets.sync="testSheets"
        :transportDocument.sync="transportDocument"
      ></card-contact>
      <div class="row justify-content-between">
        <h4 class="col">{{ $t("formCustomer.administrativeContact") }}</h4>
        <span class="col-auto d-flex align-items-center">
          <b-icon
            class="ml-auto mr-1"
            id="itsMeAdministrativeContact"
            aria-hidden="true"
            icon="question-circle"
          ></b-icon>
          <b-tooltip
            target="itsMeAdministrativeContact"
            title="Tooltip title"
            triggers="hover"
            placement="left"
          >
            {{ $t("tooltips.itsMe") }}
          </b-tooltip>
          <b-checkbox
            v-model="form.administrativeContact"
            :value="userContact('administrativeContact')"
            :unchecked-value="administrativeContactCall()"
            @change="getUserContact('administrativeContactIsUser')"
            >{{ $t("labels.itsMe") }}</b-checkbox
          >
        </span>
      </div>

      <card-contact
        class="mb-2"
        id="administrativeContact"
        ref="administrativeContact"
        :disabled="administrativeContactIsUser"
        :contact="form.administrativeContact"
        :invalidErrors="
          invalidErrors.administrativeContact
            ? invalidErrors.administrativeContact.invalid
            : []
        "
        :assignmentValue="'administrativeContact'"
        :form="form"
        :billing.sync="billing"
        :msds.sync="msds"
        :testSheets.sync="testSheets"
        :transportDocument.sync="transportDocument"
      ></card-contact>
      <h3>{{ $t("formCustomer.documentsRecipient") }}</h3>
      <div id="assignments" class="col-md-12 m-0 p-0">
        <form-contact-assigment
          id="billing"
          :assignment.sync="billing"
          :emailtype="'billing'"
          :form.sync="form"
          :invalidErrors="invalidErrors.invalid"
        />
        <form-contact-assigment
          id="transportDocument"
          :assignment.sync="transportDocument"
          :emailtype="'transportDocument'"
          :form.sync="form"
          :invalidErrors="invalidErrors.invalid"
        />
        <form-contact-assigment
          id="msds"
          :assignment.sync="msds"
          :emailtype="'msds'"
          :form.sync="form"
          :invalidErrors="invalidErrors.invalid"
        />
        <form-contact-assigment
          id="testSheets"
          :assignment.sync="testSheets"
          :emailtype="'testSheets'"
          :form.sync="form"
          :invalidErrors="invalidErrors.invalid"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CardContact from "./card-contact.vue";
import FormContactAssigment from "./form-contact-assigment.vue";
import { Contact } from "../models/customer";

export default {
  components: { CardContact, FormContactAssigment },
  name: "Contacts",
  props: {
    form: Object(),
    curretStep: Object(),
    completed: Boolean(),
    invalidErrors: Array(),
    user: Object(),
  },
  data() {
    return {
      isMounted: false,
      self: "Contacts",
      fields: [
        "purchasingContact",
        "administrativeContact",
        "billingEmail1",
        "billingEmail2",
        "transportDocumentEmail1",
        "transportDocumentEmail2",
        "msdsEmail1",
        "msdsEmail2",
        "testSheetsEmail1",
        "testSheetsEmail2",
      ],

      purchasingContactIsUser: false,
      administrativeContactIsUser: false,
      administrativeContact: this.form.administrativeContact,
      purchasingContact: this.form.purchasingContact,
      administrativeContact:this.form.administrativeContact,
      administrativeTelephone: this.form.administrativeContact.phoneNumber,
      billing: {
        assigned: null,
        email1: this.form.billingEmail1,
        email2: this.form.billingEmail2,
      },
      transportDocument: {
        assigned: null,
        email1: this.form.transportDocumentEmail1,
        email2: this.form.transportDocumentEmail2,
      },
      msds: {
        assigned: null,
        email1: this.form.msdsEmail1,
        email2: this.form.msdsEmail2,
      },
      testSheets: {
        assigned: null,
        email1: this.form.testSheetsEmail1,
        email2: this.form.testSheetsEmail2,
      },
    };
  },
  computed: {
    purchasingContactTelephone: {
      get() {
        return this.$refs.purchasingContact.telephone;
      },
    },
    administrativeContactTelephone: {
      get() {
        return this.$refs.administrativeContact.telephone;
      },
    },
  },
  methods: {
    getUserContact(disable) {
      this[disable] = !this[disable];
    },
    userContact(contact) {
      let userContact=new Contact();
      if (this.isMounted) {
        userContact = new Contact(false, false, this.user);
        userContact.role = this.form[contact].role;
      }
      return userContact;
    },
    administrativeContactCall() {
      return this.administrativeContact;
    },
    purchasingContactCall() {
      return this.purchasingContact;
    },
  },
  mounted() {
    this.isMounted = true;
    this.purchasingContactIsUser =
      Object.entries(this.form.purchasingContact).toString() ==
      Object.entries(this.userContact("purchasingContact")).toString();
    this.administrativeContactIsUser =
      Object.entries(this.form.administrativeContact).toString() ==
      Object.entries(this.userContact("administrativeContact")).toString();
  },
  watch: {
    "billing.assigned": {
      handler(self) {
         self
          ? this.billing.email1 =this.form[this.billing.assigned].email
          :null;
          this.$emit(
          "update:form",
          Object.assign(this.form, {
            billingEmail1: this.billing.email1,
            billingEmail2: this.billing.email2,
          })
        );
      },
      deep: true,
    },
    "billing.email1": {
      handler(self) {
        this.$emit(
          "update:form",
          Object.assign(this.form, {
            billingEmail1: this.billing.email1,
            billingEmail2: this.billing.email2,
          })
        );
      },
      deep: true,
    },
    "billing.email2": {
      handler() {
        this.$emit(
          "update:form",
          Object.assign(this.form, {
            billingEmail1: this.billing.email1,
            billingEmail2: this.billing.email2,
          })
        );
      },
      deep: true,
    },
    "transportDocument.assigned": {
      handler() {
        this.transportDocument.email1 = this.transportDocument.assigned
          ? this.form[this.transportDocument.assigned].email
          : this.transportDocument.email1;
      },
      deep: true,
    },
    "transportDocument.email1": {
      handler() {
        this.$emit(
          "update:form",
          Object.assign(this.form, {
            transportDocumentEmail1: this.transportDocument.email1,
            transportDocumentEmail2: this.transportDocument.email2,
          })
        );
      },
      deep: true,
    },
    "transportDocument.email2": {
      handler() {
        this.$emit(
          "update:form",
          Object.assign(this.form, {
            transportDocumentEmail1: this.transportDocument.email1,
            transportDocumentEmail2: this.transportDocument.email2,
          })
        );
      },
      deep: true,
    },
    "msds.assigned": {
      handler() {
        this.msds.email1 = this.msds.assigned
          ? this.form[this.msds.assigned].email
          : this.msds.email1;
      },
      deep: true,
    },
    "msds.email1": {
      handler() {
        this.$emit(
          "update:form",
          Object.assign(this.form, {
            msdsEmail1: this.msds.email1,
            msdsEmail2: this.msds.email2,
          })
        );
      },
      deep: true,
    },
    "msds.email2": {
      handler() {
        this.$emit(
          "update:form",
          Object.assign(this.form, {
            msdsEmail1: this.msds.email1,
            msdsEmail2: this.msds.email2,
          })
        );
      },
      deep: true,
    },
    "testSheets.assigned": {
      handler() {
        this.testSheets.email1 = this.testSheets.assigned
          ? this.form[this.testSheets.assigned].email
          : this.testSheets.email1;
      },
      deep: true,
    },
    "testSheets.email1": {
      handler() {
        this.$emit(
          "update:form",
          Object.assign(this.form, {
            testSheetsEmail1: this.testSheets.email1,
            testSheetsEmail2: this.testSheets.email2,
          })
        );
      },
      deep: true,
    },
    "testSheets.email2": {
      handler() {
        this.$emit(
          "update:form",
          Object.assign(this.form, {
            testSheetsEmail1: this.testSheets.email1,
            testSheetsEmail2: this.testSheets.email2,
          })
        );
      },
      deep: true,
    },
  },
};
</script>