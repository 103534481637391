import CheckoutService from "../services/api-services/checkout-service";
import ProductService from "../services/api-services/product-service";
import i18n from "@/plugins/i18n";
import { getProductCartFromDto } from "@/models/product";

const initialState = {
  orders: {
    info: {
      page: 1,
      row: 20,
      totalElements: 0,
      totalPages: 0,
      first: true,
      last: false,
      empty: true,
      sortDirection: "desc",
      sortBy: "createdOn",
      sortDesc: false,
    },
  },
  offers: {
    info: {
      page: 1,
      row: 20,
      totalElements: 0,
      totalPages: 0,
      first: true,
      last: false,
      empty: true,
      sortDirection: "desc",
      sortBy: "createdOn",
      sortDesc: false,
    },
  },
};

export const ordersAndOffers = {
  state: initialState,
  actions: {
    loadOffers({ commit, dispatch, getters }, filters) {
      let paginationInfo = getters.getOffersInfo;
      return CheckoutService.getOffers(
        paginationInfo.page,
        paginationInfo.row,
        "createdOn",
        paginationInfo.sortDirection,
        filters
      ).then(
        (res) => {
          console.log(res);
          console.log(paginationInfo.row);
          const response = res.data;
          let info = {
            page: paginationInfo.page,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            row: paginationInfo.row,
            fist: response.first,
            last: response.last,
            sortDirection: paginationInfo.sortDirection,
            sortBy: paginationInfo.sortBy,
            sortDesc: paginationInfo.sortDirection == "desc",
            empty: response.empty,
          };
          commit("updateInfoOffers", info);
          let content = response.content;
          return Promise.resolve(content);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    loadOrders({ commit, dispatch, getters }, filters) {
      let paginationInfo = getters.getOrdersInfo;
      return CheckoutService.getOrders(
        paginationInfo.page,
        paginationInfo.row,
        "createdOn",
        paginationInfo.sortDirection,
        filters
      ).then(
        (res) => {
          console.log(res);
          const response = res.data;
          let info = {
            page: paginationInfo.page,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            row: paginationInfo.row,
            fist: response.first,
            last: response.last,
            sortDirection: paginationInfo.sortDirection,
            sortBy: paginationInfo.sortBy,
            sortDesc: paginationInfo.sortDirection == "desc",
            empty: response.empty,
          };
          commit("updateInfoOrders", info);
          let content = response.content;
          return Promise.resolve(content);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    getProduct({ commit, dispatch, getters }, item) {
      let code =
      item.productCode.productClass + item.productCode.serial + item.productCode.color + item.productCode.pkg;
      return ProductService.getProduct(code)
        .then((response) => {
          let complete = {};
          Object.keys({ ...response.data, ...item }).map(key => {
            complete[key] = item[key] || response.data[key];
          });

          let product = getProductCartFromDto(complete);
          if (product.findDescriptionPerLang) {
            product.description = product.findDescriptionPerLang(
              getters.getUser.language,
              i18n.fallbackLocale
            );
          }
          console.log(item, product, complete);
          return Promise.resolve(product);
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    },
    async getProductAsync({ commit, dispatch, getters }, item) {
      let code =
      item.productCode.productClass + item.productCode.serial + item.productCode.color + item.productCode.pkg;
      let response = await ProductService.getProduct(code).catch((e) => {
        return item;
      });

      let complete = {};
      Object.keys({ ...response.data, ...item }).map(key => {
        complete[key] = item[key] || response.data[key];
      });

      let product = getProductCartFromDto(complete);
      if (product.findDescriptionPerLang) {
        product.description = product.findDescriptionPerLang(
          getters.getUser.language,
          i18n.fallbackLocale
        );
      }
      console.log(item, product, complete);
      return product;
    }
  },

  mutations: {
    updateInfoOffers(state, info) {
      state.offers.info = info;
    },
    updateInfoOrders(state, info) {
      state.orders.info = info;
    },
  },

  getters: {
    getOfferTotalItems: (state) => state.offers.info.totalElements,
    getOffersInfo: (state) => state.offers.info,
    getOrderTotalItems: (state) => state.orders.info.totalElements,
    getOrdersInfo: (state) => state.orders.info,
  },
};
