export const formCustomer = {
  vatNumber: "VAT number",
  noPrefix: "no area code",
  sdiCode: "SDI code",
  pec: "PEC",
  streetAddress1: "Address Line 1",
  streetAddress2: "Address Line 2",
  city: "City",
  billing: "Invoice",
  transportDocument: "DDT",
  msds: "MSDS",
  testSheets: "Testing",
  businessAddress: "Administrative headquarters",
  legalAddressSameAsbusinessAddress: "Does the registered office coincide with the administrative office?",
  legalAddress: "Registered office",
  shippingAddressesSameAsbusinessAddress: "Does the shipping location coincide with the administrative office?",
  shippingAddresses: "Shipping location",
  addAddress: "Add address",
  purchasingContact: "Contact Purchasing",
  administrativeContact: "Contact Administration",
  documentsToRecive: "Documents to send to this contact",
  documentsRecipient: "Document recipients",
  additionalContact: "Additional Contact",
  additionalContacts: "Additional Contacts",
  billingRecipient: "Invoice recipient",
  transportDocumentRecipient: "Recipient of transport documents",
  msdsRecipient: "Recipient of safety data sheets",
  testSheetsRecipient: "Test card recipient",
  bankName: "Bank",
  iban: "IBAN Code",
  customerCode: "Customer code",
  customerCodeShort: "Customer",
  customerCodeText: "If you have the customer code, fill in the field to request to be associated directly",
  marketSegmentation: "Segmentation",
  marketSegmentationPlaceholder: "Type of membership",
  resellingType: "Typology",
  marketCompetitor: "Competitor",
  marketCompetitorPlaceholder: "Competitor company",
  totalConsumption: "Total consumption  ",
  totalConsumptionPlaceholder: "Total consumption in €",
  alceaPotential: "Alcea potential",
  alceaPotentialPlaceholder: "Alcea potential in €",
  tntNextRequired: "Requires TNT NEXT"
};
