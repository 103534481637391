<template>
  <div class="row">
    <h2>{{ $t("titles.addresses") }}</h2>
    <div class="col-md-12">
      <h3>{{ $t("formCustomer.businessAddress") }}</h3>
      <card-address
        :isShippingAddress="false"
        :invalidErrors="
          invalidErrors.businessAddress
            ? invalidErrors.businessAddress.invalid
            : []
        "
        :address.sync="localBusinessAddress"
      ></card-address>
      <span class=""
        ><b-checkbox v-model="legalAddressSameAsbusinessAddress">
          {{ $t("formCustomer.legalAddressSameAsbusinessAddress") }}</b-checkbox
        ></span
      >
    </div>
    <div class="col-md-12">
      <h3>{{ $t("formCustomer.legalAddress") }}</h3>
      <card-address
        :isShippingAddress="false"
        :disabled="legalAddressSameAsbusinessAddress"
        :address.sync="localLegalAddress"
        :invalidErrors="
          invalidErrors.legalAddress ? invalidErrors.legalAddress.invalid : []
        "
      ></card-address>
      <!--  max 500 -->
      <span class="">
        <b-checkbox v-model="shippingAddressesSameAsbusinessAddress">
          {{
            $t("formCustomer.shippingAddressesSameAsbusinessAddress")
          }}</b-checkbox
        >
      </span>
    </div>
    <div class="col-md-12">
      <h3>{{ $t("formCustomer.shippingAddresses") }}</h3>
      <card-address
        v-for="(ad, index) in localShippingAddresses"
        :isShippingAddress="true"
        :key="index"
        :disabled="index == 0 && shippingAddressesSameAsbusinessAddress"
        v-bind:address.sync="localShippingAddresses[index]"
        :dismissable="index > 0"
        :invalidErrors="
          invalidErrors.shippingAddresses &&
          invalidErrors.shippingAddresses[index]
            ? invalidErrors.shippingAddresses[index].invalid
            : []
        "
        @removeAddress="removeAddress(index)"
      >
      </card-address>
      <b-button @click="addAddress">{{
        $t("formCustomer.addAddress")
      }}</b-button>
    </div>
  </div>
</template>
<script>
import cardAddress from "./card-address.vue";
import { Address,sortShippingAdddresses } from "../models/customer";
export default {
  components: { cardAddress },
  name: "Addresses",
  props: {
    businessName: String,
    shippingAddresses: Array(),
    legalAddress: Object(),
    businessAddress: Object(),
    user: Object(),
    curretStep: Object(),
    completed: Boolean(),
    invalidErrors: Array(),
  },
  data() {
    return {
      self: "Addresses",
      fields: ["businessAddress", "legalAddress", "shippingAddresses"],
      legalAddressSameAsbusinessAddress: false,
      shippingAddressesSameAsbusinessAddress: false,
      localBusinessAddress: this.businessAddress,
      localLegalAddress: this.legalAddress,
      localShippingAddresses: this.shippingAddresses,
    };
  },
  computed: {
    businessAddressWatcher() {
      return this.localBusinessAddress;
    },
  },
  methods: {
    addAddress() {
      if (this.localShippingAddresses.length < 500) {
        let empty = true;
        let address = {
          ...new Address(),
          ...{
            alceaOrder: this.localShippingAddresses.length + 1,
          },
        };
        this.localShippingAddresses.push(address);
        this.$emit("update:shippingAddresses", this.localShippingAddresses);
      }
    },
    removeAddress(address) {
      this.localShippingAddresses.splice(address, 1);
      this.$emit("update:shippingAddresses", this.localShippingAddresses);
    },
  },
  created() {
    this.localBusinessAddress = this.businessAddress;
    this.localBusinessAddress.businessName = this.businessName;
    this.localLegalAddress = this.legalAddress;
    this.localShippingAddresses = this.shippingAddresses;

    this.legalAddressSameAsbusinessAddress = this.businessAddress.equal(
      this.legalAddress
    );
    this.shippingAddressesSameAsbusinessAddress = this.businessAddress.equal(
      this.shippingAddresses[0]
    );

    if(this.shippingAddressesSameAsbusinessAddress) {
      this.localShippingAddresses[0].businessName = this.businessName;
    }

    this.$emit("update:shippingAddresses",this.localShippingAddresses);
  },
  watch: {
    legalAddressSameAsbusinessAddress: function (newVal, oldVal) {
      this.localLegalAddress = newVal
        ? this.businessAddressWatcher
        : new Address();
        
    },
    shippingAddressesSameAsbusinessAddress: function (newVal, oldVal) {
      this.localShippingAddresses[0] = newVal
        ? this.businessAddressWatcher
        :{...new Address(),...{
          alceaOrder:1
        }},
        this.localShippingAddresses[0].businessName = this.businessName;
        sortShippingAdddresses(this.localShippingAddresses);
        this.$emit("update:shippingAddresses",this.localShippingAddresses);
    },
    localLegalAddress: {
      handler() {
        this.$emit("update:legalAddress", this.localLegalAddress);
      },
      deep: true,
    },
    localBusinessAddress: {
      handler() {
        this.$emit("update:businessAddress", this.localBusinessAddress);
        this.$emit("update:shippingAddresses", this.localShippingAddresses);
        this.$emit("fieldUpdated")
      },
      deep: true,
    },
    localShippingAddresses: {
      handler() {
        sortShippingAdddresses(this.localShippingAddresses)
        this.$emit("update:shippingAddresses", this.localShippingAddresses);
        this.$emit("fieldUpdated")
      },
      deep: true,
    },
    businessName: {
      handler() {
        this.localBusinessAddress.businessName = this.businessName;
        if(this.shippingAddressesSameAsbusinessAddress) {
          this.localShippingAddresses[0].businessName = this.businessName;
        }
      }
    }
  },
};
</script>
