export const productsSearchB2BCombos = {
  filters: {
    areas: [
      {
        text: 'productsSearch.combos.areas.edil',
        value: 'E'
      },
      {
        text: 'productsSearch.combos.areas.metal',
        value: 'F'
      },
      {
        text: 'productsSearch.combos.areas.wood',
        value: 'L'
      },
      {
        text: 'productsSearch.combos.areas.edil_metal_wood',
        value: 'EFL'
      },
      {
        text: 'productsSearch.combos.areas.coil',
        value: 'C'
      },
      {
        text: 'productsSearch.combos.areas.plastic',
        value: 'P'
      },
      {
        text: 'productsSearch.combos.areas.paste',
        value: 'R'
      },
      {
        text: 'productsSearch.combos.areas.corrosion',
        value: 'A'
      }
    ],
    typologies: [
      {
        text: 'productsSearch.combos.typologies.dye',
        value: 'N'
      },
      {
        text: 'productsSearch.combos.typologies.paste',
        value: 'P'
      },
      {
        text: 'productsSearch.combos.typologies.background',
        value: 'F'
      },
      {
        text: 'productsSearch.combos.typologies.intermediate',
        value: 'I'
      },
      {
        text: 'productsSearch.combos.typologies.washable',
        value: 'LA'
      },
      {
        text: 'productsSearch.combos.typologies.various',
        value: 'V'
      },
      {
        text: 'productsSearch.combos.typologies.catalyst',
        value: 'H'
      },
      {
        text: 'productsSearch.combos.typologies.thinner',
        value: 'D'
      },
      {
        text: 'productsSearch.combos.typologies.converter',
        value: 'C'
      },
      {
        text: 'productsSearch.combos.typologies.dust',
        value: 'O'
      },
      {
        text: 'productsSearch.combos.typologies.back',
        value: 'B'
      },
      {
        text: 'productsSearch.combos.typologies.impregnant',
        value: 'R'
      },
      {
        text: 'productsSearch.combos.typologies.coat',
        value: 'T'
      },
      {
        text: 'productsSearch.combos.typologies.raw_material',
        value: 'MP'
      },
      {
        text: 'productsSearch.combos.typologies.wp_floors_resins',
        value: 'PR'
      }
    ],
    families: [
      {
        text: 'productsSearch.combos.families.acrylic',
        value: 'A'
      },
      {
        text: 'productsSearch.combos.families.pvdf',
        value: 'D'
      },
      {
        text: 'productsSearch.combos.families.epoxy',
        value: 'E'
      },
      {
        text: 'productsSearch.combos.families.ketone',
        value: 'H'
      },
      {
        text: 'productsSearch.combos.families.polyester',
        value: 'I'
      },
      {
        text: 'productsSearch.combos.families.alkyl',
        value: 'L'
      },
      {
        text: 'productsSearch.combos.families.nitrocellulose',
        value: 'N'
      },
      {
        text: 'productsSearch.combos.families.unsaturated',
        value: 'P'
      },
      {
        text: 'productsSearch.combos.families.clorocianuro',
        value: 'R'
      },
      {
        text: 'productsSearch.combos.families.phenolic',
        value: 'F'
      },
      {
        text: 'productsSearch.combos.families.silicones',
        value: 'S'
      },
      {
        text: 'productsSearch.combos.families.urethane',
        value: 'U'
      },
      {
        text: 'productsSearch.combos.families.vinyl',
        value: 'V'
      },
      {
        text: 'productsSearch.combos.families.polyester_melamine',
        value: 'M'
      }
    ],
    dilutions: [
      {
        text: 'productsSearch.combos.dilutions.water',
        value: 'W'
      },
      {
        text: 'productsSearch.combos.dilutions.dust',
        value: 'P'
      },
      {
        text: 'productsSearch.combos.dilutions.solvent',
        value: 'S'
      },
      {
        text: 'productsSearch.combos.dilutions.noSolvent',
        value: 'N'
      }
    ],
    dryings: [
      {
        text: 'productsSearch.combos.dryings.air',
        value: '1'
      },
      {
        text: 'productsSearch.combos.dryings.catalyzed',
        value: '2'
      },
      {
        text: 'productsSearch.combos.dryings.electrophoretic',
        value: 'E'
      },
      {
        text: 'productsSearch.combos.dryings.oven',
        value: 'O'
      },
      {
        text: 'productsSearch.combos.dryings.curing',
        value: 'U'
      }
    ],
    appMethods: [
      {
        text: 'productsSearch.combos.appMethods.brush',
        value: 'P'
      },
      {
        text: 'productsSearch.combos.appMethods.roller',
        value: 'R'
      },
      {
        text: 'productsSearch.combos.appMethods.sprinkle',
        value: 'S'
      },
      {
        text: 'productsSearch.combos.appMethods.airMix',
        value: 'SM'
      },
      {
        text: 'productsSearch.combos.appMethods.airLess',
        value: 'SL'
      },
      {
        text: 'productsSearch.combos.appMethods.spatula',
        value: 'T'
      },
      {
        text: 'productsSearch.combos.appMethods.sponge',
        value: 'G'
      },
      {
        text: 'productsSearch.combos.appMethods.rollerCoating',
        value: 'C'
      },
      {
        text: 'productsSearch.combos.appMethods.curtainCoater',
        value: 'V'
      },
      {
        text: 'productsSearch.combos.appMethods.immersion',
        value: 'I'
      },
      {
        text: 'productsSearch.combos.appMethods.trowel',
        value: 'Z'
      },
      {
        text: 'productsSearch.combos.appMethods.cloth',
        value: 'A'
      },
      {
        text: 'productsSearch.combos.appMethods.anode',
        value: 'IEA'
      },
      {
        text: 'productsSearch.combos.appMethods.cathode',
        value: 'IEC'
      }
    ]
  },

  categories: {
    areas: {
      E: 'productsSearch.combos.areas.edil',
      F: 'productsSearch.combos.areas.metal',
      L: 'productsSearch.combos.areas.wood',
      EFL: 'productsSearch.combos.areas.edil_metal_wood',
      C: 'productsSearch.combos.areas.coil',
      P: 'productsSearch.combos.areas.plastic',
      R: 'productsSearch.combos.areas.paste',
      A: 'productsSearch.combos.areas.corrosion'
    },
    typologies: {
      N: 'productsSearch.combos.typologies.dye',
      P: 'productsSearch.combos.typologies.paste',
      F: 'productsSearch.combos.typologies.background',
      I: 'productsSearch.combos.typologies.intermediate',
      LA: 'productsSearch.combos.typologies.washable',
      V: 'productsSearch.combos.typologies.various',
      H: 'productsSearch.combos.typologies.catalyst',
      D: 'productsSearch.combos.typologies.thinner',
      C: 'productsSearch.combos.typologies.converter',
      O: 'productsSearch.combos.typologies.dust',
      B: 'productsSearch.combos.typologies.back',
      R: 'productsSearch.combos.typologies.impregnant',
      T: 'productsSearch.combos.typologies.coat',
      MP: 'productsSearch.combos.typologies.raw_material',
      PR: 'productsSearch.combos.typologies.wp_floors_resins'
    },
    families: {
      A: 'productsSearch.combos.families.acrylic',
      D: 'productsSearch.combos.families.pvdf',
      E: 'productsSearch.combos.families.epoxy',
      H: 'productsSearch.combos.families.ketone',
      I: 'productsSearch.combos.families.polyester',
      L: 'productsSearch.combos.families.alkyl',
      N: 'productsSearch.combos.families.nitrocellulose',
      P: 'productsSearch.combos.families.unsaturated',
      R: 'productsSearch.combos.families.clorocianuro',
      F: 'productsSearch.combos.families.phenolic',
      S: 'productsSearch.combos.families.silicones',
      U: 'productsSearch.combos.families.urethane',
      V: 'productsSearch.combos.families.vinyl',
      M: 'productsSearch.combos.families.polyester_melamine',
    },
    dilutions: {
      W: 'productsSearch.combos.dilutions.water',
      P: 'productsSearch.combos.dilutions.dust',
      S: 'productsSearch.combos.dilutions.solvent',
      N: 'productsSearch.combos.dilutions.noSolvent'
    },
    dryings: {
      1: 'productsSearch.combos.dryings.air',
      2: 'productsSearch.combos.dryings.catalyzed',
      E: 'productsSearch.combos.dryings.electrophoretic',
      O: 'productsSearch.combos.dryings.oven',
      U: 'productsSearch.combos.dryings.curing'
    }
  },

  dilutionIcons: {
    W: {
      icon: 'DILUIZIONE_acqua',
      desc: 'productsSearch.dilution_water'
    },
    S: {
      icon: 'DILUIZIONE_solvente',
      desc: 'productsSearch.dilution_solvent'
    },
  },

  characteristicsCols: [
    {
      key: 'code',
      label: 'productsSearch.code'
    },
    {
      key: 'desc',
      label: 'productsSearch.color'
    },
    {
      key: 'specWeight',
      label: 'productsSearch.specificWeight'
    },
    {
      key: 'unitValue',
      label: 'productsSearch.unitValue'
    },
    {
      key: 'actions',
      label: 'productsSearch.actions',
      tdClass: 'actions-td'
    }
  ],

  intExtIcons: {
    I: {
      icon: 'USO_interno',
      desc: 'productsSearch.internal_use'
    },
    E: {
      icon: 'USO_esterno',
      desc: 'productsSearch.external_use'
    },
    IE: {
      icon: 'USO_esterno_interno',
      desc: 'productsSearch.external_internal_use'
    }
  },

  applicationIcons: {
    P: {
      icon: 'APPLICAZIONI_pennello',
      desc: 'productsSearch.combos.appMethods.brush'
    },
    R: {
      icon: 'APPLICAZIONI_rullo',
      desc: 'productsSearch.combos.appMethods.roller'
    },
    S: {
      icon: 'APPLICAZIONI_airspray',
      desc: 'productsSearch.combos.appMethods.sprinkle'
    },
    SA: {
      icon: 'APPLICAZIONI_airspray',
      desc: 'productsSearch.combos.appMethods.conventional'
    },
    SM: {
      icon: 'APPLICAZIONI_airless',
      desc: 'productsSearch.combos.appMethods.airMix'//
    },
    SL: {
      icon: 'APPLICAZIONI_airless',
      desc: 'productsSearch.combos.appMethods.airLess'
    },
    T: {
      icon: 'APPLICAZIONI_spatola',
      desc: 'productsSearch.combos.appMethods.spatula'
    },
    G: {
      icon: 'APPLICAZIONI_spugna',
      desc: 'productsSearch.combos.appMethods.sponge'
    },
    C: {
      icon: 'APPLICAZIONI_Roller_Coating',
      desc: 'productsSearch.combos.appMethods.rollerCoating'
    },
    V: {
      icon: 'APPLICAZIONI_velatrice',
      desc: 'productsSearch.combos.appMethods.curtainCoater'
    },
    I: {
      icon: 'APPLICAZIONI_immersione',
      desc: 'productsSearch.combos.appMethods.immersion'
    },
    Z: {
      icon: 'APPLICAZIONI_frattazzo',
      desc: 'productsSearch.combos.appMethods.trowel'
    },
    A: {
      icon: 'APPLICAZIONI_straccio',
      desc: 'productsSearch.combos.appMethods.cloth'
    },
    F: {
      icon: 'APPLICAZIONI_flow_coatings',
      desc: 'productsSearch.combos.appMethods.flowCoatings'
    },
    SH: {
      icon: 'APPLICAZIONI_hplv',
      desc: 'productsSearch.combos.appMethods.hplv'
    },
    SD: {
      icon: 'APPLICAZIONI_disco_elettrostatica',
      desc: 'productsSearch.combos.appMethods.electrostaticSpray'
    },
    IEA: {
      icon: 'APPLICAZIONI_Elettroforesi_NS',
      desc: 'productsSearch.combos.appMethods.electrophoresisNS'
    },
    IEC: {
      icon: 'APPLICAZIONI_Elettroforesi_NS',
      desc: 'productsSearch.combos.appMethods.electrophoresisNS'
    }
  }
}
