import Validator from "../services/validation-service/validator-service";
export class Customer {
  //AnagraphicsInfo fileds
  customerCode;
  businessName;
  vatNumber;
  italianFiscalCode;
  sdiCode;
  pec;
  //CompanyPerspective fields
  marketSegmentation1;
  marketSegmentation2;
  marketSegmentation3;
  marketSegmentation4;
  marketCompetitor1;
  marketCompetitor2;
  totalConsumption;
  alceaPotential;
  isRetailerOfMetal;
  isRetailerOfWood;
  isRetailerOfConstruction;
  isRetailerOfBodywork;
  isRetailerOfOwnership;
  retailerShopWindows;
  retailerSurface;
  tntNextRequired;
  unconditionalDiscountPct;
  //Addresses fields
  businessAddress = new Address();
  legalAddress = new Address();
  shippingAddresses = sortShippingAdddresses(
    new Array({
      ...new Address(),
      ...{ alceaOrder: Number() },
    })
  );
  purchasingContact = new Contact();
  administrativeContact = new Contact();
  additionalContacts = sortAdditionalContacts(
    new Array(new AdditionalContact(true))
  );
  billingEmail1;
  billingEmail2;
  transportDocumentEmail1;
  transportDocumentEmail2;
  msdsEmail1;
  msdsEmail2;
  testSheetsEmail1;
  testSheetsEmail2;
  //BankInformations fields
  bankName;
  iban;
}

export class Address {
  businessName;
  streetAddress1;
  streetAddress2;
  country;
  city;
  zipOrPostalCode;
  province;
  notes;
  isDefault;
  constructor(emptyDraft, dataAddress, formToPostContact) {
    console.log(dataAddress);
    if (emptyDraft) {
      this.businessName= "";
      this.streetAddress1 = "";
      this.streetAddress2 = "";
      this.country = "";
      this.city = "";
      this.zipOrPostalCode = "";
      this.province = "";
      this.notes = "";
      this.isDefault = false;
    } else {
      if (dataAddress) {
        this.businessName = dataAddress.businessName;
        this.streetAddress1 = dataAddress.streetAddress1;
        this.streetAddress2 = dataAddress.streetAddress2;
        this.country = dataAddress.country;
        this.city = dataAddress.city;
        this.zipOrPostalCode = dataAddress.zipOrPostalCode;
        this.province = dataAddress.province;
        this.notes = dataAddress.notes;
        this.isDefault = dataAddress.isDefault
      } else {
        if (formToPostContact) {
          this.streetAddress1 = Validator.valid(
            formToPostContact.streetAddress1
          )
            ? formToPostContact.streetAddress1
            : null;
          this.streetAddress2 = Validator.valid(
            formToPostContact.streetAddress2
          )
            ? formToPostContact.streetAddress2
            : null;
          this.country = Validator.valid(formToPostContact.country)
            ? formToPostContact.country
            : null;
          this.city = Validator.valid(formToPostContact.city)
            ? formToPostContact.city
            : null;
          this.zipOrPostalCode = Validator.valid(
            formToPostContact.zipOrPostalCode
          )
            ? formToPostContact.zipOrPostalCode
            : null;
          this.notes = Validator.valid(formToPostContact.notes)
            ? formToPostContact.notes
            : null;
          this.businessName = Validator.valid(formToPostContact.businessName)
            ? formToPostContact.businessName
            : null;
          this.province = Validator.valid(formToPostContact.province)
            ? formToPostContact.province
            : null;
        }
      }
    }
  }
  equal(address) {
    return (
      //this.businessName == address.businessAddress &&
      this.streetAddress1 == address.streetAddress1 &&
      this.streetAddress2 == address.streetAddress2 &&
      this.country == address.country &&
      this.city == address.city &&
      this.zipOrPostalCode == address.zipOrPostalCode &&
      this.province == address.province &&
      this.note == address.note
    );
  }
}
export function sortShippingAdddresses(arrayAddresses) {
  let shippingAddresses = arrayAddresses.map((element, index) => {
    return {
      ...new Address(false, element),
      ...{
        alceaOrder: Validator.valid(element.alceaOrder)
          ? element.alceaOrder
          : index + 1,
      },
    };
  });
  return shippingAddresses.sort(function (a, b) {
    return a.alceaOrder - b.alceaOrder;
  });
}
export function sortAdditionalContacts(arrayContacts) {
  let additionalContacts = arrayContacts.map((element, index) => {
    element.alceaOrder = Validator.valid(element.alceaOrder)
      ? element.alceaOrder
      : index + 1;
    return element;
  });
  return additionalContacts.sort(function (a, b) {
    return a.alceaOrder - b.alceaOrder;
  });
}
export class Contact {
  gender;
  firstName;
  lastName;
  role;
  email;
  phoneNumber;
  constructor(emptyDraft, dataContact, userContact, formToPostContact) {
    if (emptyDraft) {
      this.gender = "";
      this.firstName = "";
      this.lastName = "";
      this.role = "";
      this.email = "";
      this.phoneNumber = "";
    } else {
      if (dataContact) {
        this.gender = dataContact.gender;
        this.firstName = dataContact.firstName;
        this.lastName = dataContact.lastName;
        this.role = dataContact.role;
        this.email = dataContact.email;
        this.phoneNumber = dataContact.phoneNumber;
      } else if (userContact) {
        this.gender = userContact.gender;
        this.firstName = userContact.firstname;
        this.lastName = userContact.lastname;
        this.email = userContact.email;
        this.phoneNumber = userContact.telephone;
      } else if (formToPostContact) {
        this.gender = Validator.valid(formToPostContact.gender)
          ? formToPostContact.gender
          : null;
        this.firstName = Validator.valid(formToPostContact.gender)
          ? formToPostContact.firstName
          : null;
        this.lastName = Validator.valid(formToPostContact.gender)
          ? formToPostContact.lastName
          : null;
        this.role = Validator.valid(formToPostContact.gender)
          ? formToPostContact.role
          : null;
        this.email = Validator.valid(formToPostContact.gender)
          ? formToPostContact.email
          : null;
        this.phoneNumber = Validator.valid(formToPostContact.gender)
          ? formToPostContact.phoneNumber
          : null;
      }
    }
  }
}
/*"administrativeContact.department: non deve essere null;  
 purchasingContact.department: non deve essere null"*/
export class AdditionalContact {
  alceaOrder;
  gender;
  firstName;
  lastName;
  role;
  email;
  phoneNumber;
  department;
  constructor(emptyDraft, dataContact, userContact, formToPostContact) {
    if (emptyDraft) {
      this.alceaOrder = Number();
      this.gender = null;
      this.firstName = "";
      this.lastName = "";
      this.role = null;
      this.email = "";
      this.phoneNumber = null;
      this.department = null;
    } else {
      if (dataContact) {
        this.alceaOrder = dataContact.alceaOrder;
        this.gender = dataContact.gender;
        this.firstName = dataContact.firstName;
        this.lastName = dataContact.lastName;
        this.role = dataContact.role;
        this.email = dataContact.email;
        this.phoneNumber = dataContact.phoneNumber;
        this.department = dataContact.department;
      } else if (userContact) {
        this.alceaOrder = userContact.alceaOrder;
        this.gender = userContact.gender;
        this.firstName = userContact.firstname;
        this.lastName = userContact.lastname;
        this.department = userContact.department;
        this.role = userContact.businessRole;
        //this.role=null
        this.email = userContact.email;
        this.phoneNumber = userContact.telephone;
      } else if (formToPostContact) {
        this.alceaOrder = Validator.valid(formToPostContact.alceaOrder)
          ? formToPostContact.alceaOrder
          : null;
        this.gender = Validator.valid(formToPostContact.gender)
          ? formToPostContact.gender
          : null;
        this.firstName = Validator.valid(formToPostContact.firstName)
          ? formToPostContact.firstName
          : null;
        this.lastName = Validator.valid(formToPostContact.lastName)
          ? formToPostContact.lastName
          : null;
        this.role = Validator.valid(formToPostContact.role)
          ? formToPostContact.role
          : null;
        this.email = Validator.valid(formToPostContact.email)
          ? formToPostContact.email
          : null;
        this.phoneNumber = Validator.valid(formToPostContact.phoneNumber)
          ? formToPostContact.phoneNumber
          : null;

        this.department = Validator.valid(formToPostContact.department)
          ? formToPostContact.department
          : null;
      }
    }
  }
}
export function getCustomerForm(emptyDraft, dataCustomer, agent) {
  let form;
  if (emptyDraft) {
    form = {
      businessName: "",
      vatNumber: "",
      italianFiscalCode: null,
      sdiCode: null,
      pec: null,
      businessAddress: new Address(),
      legalAddress: new Address(),
      shippingAddresses: sortShippingAdddresses(
        new Array({
          ...new Address(),
          ...{ alceaOrder: Number() },
        })
      ),
      purchasingContact: new Contact(),
      administrativeContact: new Contact(),
      billingEmail1: null,
      billingEmail2: null,
      transportDocumentEmail1: null,
      transportDocumentEmail2: null,
      msdsEmail1: null,
      msdsEmail2: null,
      testSheetsEmail1: null,
      testSheetsEmail2: null,
      bankName: "",
      iban: "",
    };
    if (agent) {
      form = {
        ...form,
        ...{
          marketSegmentation1: null,
          marketSegmentation2: null,
          marketSegmentation3: null,
          marketSegmentation4: null,
          marketCompetitor1: null,
          marketCompetitor2: null,
          totalConsumption: 0,
          alceaPotential: 0,
          isRetailerOfMetal: false,
          isRetailerOfWood: false,
          isRetailerOfConstruction: false,
          isRetailerOfBodywork: false,
          isRetailerOfOwnership: false,
          retailerShopWindows: 0,
          retailerSurface: 0,
          tntNextRequired: false,
          additionalContacts: sortAdditionalContacts(new Array()),
        },
      };
    }
  } else {
    if (dataCustomer) {
      form = {
        businessName: dataCustomer.businessName,
        vatNumber: dataCustomer.vatNumber,
        italianFiscalCode: dataCustomer.italianFiscalCode,
        sdiCode: dataCustomer.sdiCode,
        pec: dataCustomer.pec,
        businessAddress: new Address(false, dataCustomer.businessAddress),
        legalAddress: new Address(false, dataCustomer.legalAddress),
        shippingAddresses: sortShippingAdddresses(
          dataCustomer.shippingAddresses
        ),
        purchasingContact: new Contact(false, dataCustomer.purchasingContact),
        administrativeContact: new Contact(
          false,
          dataCustomer.administrativeContact
        ),
        billingEmail1: dataCustomer.billingEmail1,
        billingEmail2: dataCustomer.billingEmail2,
        transportDocumentEmail1: dataCustomer.transportDocumentEmail1,
        transportDocumentEmail2: dataCustomer.transportDocumentEmail2,
        msdsEmail1: dataCustomer.msdsEmail1,
        msdsEmail2: dataCustomer.msdsEmail2,
        testSheetsEmail1: dataCustomer.testSheetsEmail1,
        testSheetsEmail2: dataCustomer.testSheetsEmail2,
        bankName: dataCustomer.bankName,
        iban: dataCustomer.iban,
      };
      if (agent) {
        form = {
          ...form,
          ...{
            marketSegmentation1: dataCustomer.marketSegmentation1,
            marketSegmentation2: dataCustomer.marketSegmentation2,
            marketSegmentation3: dataCustomer.marketSegmentation3,
            marketSegmentation4: dataCustomer.marketSegmentation4,
            marketCompetitor1: dataCustomer.marketCompetitor1,
            marketCompetitor2: dataCustomer.marketCompetitor2,
            totalConsumption: dataCustomer.totalConsumption,
            alceaPotential: dataCustomer.alceaPotential,
            isRetailerOfMetal: dataCustomer.isRetailerOfMetal,
            isRetailerOfWood: dataCustomer.isRetailerOfWood,
            isRetailerOfConstruction: dataCustomer.isRetailerOfConstruction,
            isRetailerOfBodywork: dataCustomer.isRetailerOfBodywork,
            isRetailerOfOwnership: dataCustomer.isRetailerOfOwnership,
            retailerShopWindows: dataCustomer.retailerShopWindows,
            retailerSurface: dataCustomer.retailerSurface,
            tntNextRequired: dataCustomer.tntNextRequired,
            additionalContacts: sortAdditionalContacts(
              dataCustomer.additionalContacts.map((element) => {
                return new AdditionalContact(false, element);
              })
            ),
          },
        };
      }
    }
  }
  return form;
}

export function getCustomerCompleteForm(draft, agent) {
  let form;
  if (draft) {
    form = {
      businessName: Validator.valid(draft.businessName)
        ? draft.businessName
        : null,
      vatNumber: Validator.valid(draft.vatNumber) ? draft.vatNumber : null,
      italianFiscalCode: Validator.valid(draft.italianFiscalCode)
        ? draft.italianFiscalCode
        : null,
      sdiCode: Validator.valid(draft.sdiCode) ? draft.sdiCode : null,
      pec: Validator.valid(draft.pec) ? draft.pec : null,

      businessAddress: new Address(false, null, draft.businessAddress),

      legalAddress: new Address(false, null, draft.legalAddress),
      shippingAddresses: sortShippingAdddresses(draft.shippingAddresses),
      purchasingContact: new Contact(
        false,
        null,
        null,
        draft.purchasingContact
      ),
      administrativeContact: new Contact(
        false,
        null,
        null,
        draft.administrativeContact
      ),
      billingEmail1: Validator.valid(draft.billingEmail1)
        ? draft.billingEmail1
        : null,
      billingEmail2: Validator.valid(draft.billingEmail2)
        ? draft.billingEmail2
        : null,
      transportDocumentEmail1: Validator.valid(draft.transportDocumentEmail1)
        ? draft.transportDocumentEmail1
        : null,
      transportDocumentEmail2: Validator.valid(draft.transportDocumentEmail2)
        ? draft.transportDocumentEmail2
        : null,
      msdsEmail1: Validator.valid(draft.msdsEmail1) ? draft.msdsEmail1 : null,
      msdsEmail2: Validator.valid(draft.msdsEmail2) ? draft.msdsEmail2 : null,
      testSheetsEmail1: Validator.valid(draft.testSheetsEmail1)
        ? draft.testSheetsEmail1
        : null,
      testSheetsEmail2: Validator.valid(draft.testSheetsEmail2)
        ? draft.testSheetsEmail2
        : null,
      bankName: Validator.valid(draft.bankName) ? draft.bankName : null,
      iban: Validator.valid(draft.iban) ? draft.iban : null,
    };
    if (agent) {
      form = {
        ...form,
        ...{
          marketSegmentation1: Validator.valid(draft.marketSegmentation1)
            ? draft.marketSegmentation1
            : null,
          marketSegmentation2: Validator.valid(draft.marketSegmentation2)
            ? draft.marketSegmentation2
            : null,
          marketSegmentation3: Validator.valid(draft.marketSegmentation3)
            ? draft.marketSegmentation3
            : null,
          marketSegmentation4: Validator.valid(draft.marketSegmentation4)
            ? draft.marketSegmentation4
            : null,
          marketCompetitor1: Validator.valid(draft.marketCompetitor1)
            ? draft.marketCompetitor1
            : null,
          marketCompetitor2: Validator.valid(draft.marketCompetitor2)
            ? draft.marketCompetitor2
            : null,
          totalConsumption: Validator.valid(draft.totalConsumption)
            ? draft.totalConsumption
            : null,
          alceaPotential: Validator.valid(draft.alceaPotential)
            ? draft.alceaPotential
            : null,
          isRetailerOfMetal: Validator.valid(draft.isRetailerOfMetal)
            ? draft.isRetailerOfMetal
            : null,
          isRetailerOfWood: Validator.valid(draft.isRetailerOfWood)
            ? draft.isRetailerOfWood
            : null,
          isRetailerOfConstruction: Validator.valid(
            draft.isRetailerOfConstruction
          )
            ? draft.isRetailerOfConstruction
            : null,
          isRetailerOfBodywork: Validator.valid(draft.isRetailerOfBodywork)
            ? draft.isRetailerOfBodywork
            : null,
          isRetailerOfOwnership: Validator.valid(draft.isRetailerOfOwnership)
            ? draft.isRetailerOfOwnership
            : null,
          retailerShopWindows: Validator.valid(draft.retailerShopWindows)
            ? draft.retailerShopWindows
            : null,
          retailerSurface: Validator.valid(draft.retailerSurface)
            ? draft.retailerSurface
            : null,
          tntNextRequired: Validator.valid(draft.tntNextRequired)
            ? draft.tntNextRequired
            : null,
          additionalContacts: dataCustomer.additionalContacts.map((element) => {
            return new AdditionalContact(false, element);
          }),
        },
      };
    }
  }
  return form;
}

export default new Customer();
