<template>
  <div>

    <div class="pc-subtitle">
      <b-button v-if="editable"
                @click="toEdit"
                variant="outline-primary"
                class="mx-3">
        {{ $t("actions.edit") }}
        <b-icon-pencil :title="$t('actions.edit')" aria-hidden="true"/>
      </b-button>

      <div v-else class="mx-3 row align-items-center font-weight-bold">
        <b-iconstack class="mx-1" font-scale="2">
          <b-icon stacked icon="pencil" scale="0.70"></b-icon>
          <b-icon stacked icon="slash-circle" variant="danger" rotate="90"></b-icon>
        </b-iconstack>
        {{ $t("labels.editRequestPending") }}
      </div>
    </div>

    <feedback-alert class="w-100"
                    :status="feedbackStatus"
                    :message="feedbackMessage"
                    @clearStatus="clearStatus"/>

    <customer-recap :aria-disabled="true"
                    :form="customer"
                    :invalidErrors="invalidErrors">
    </customer-recap>
  </div>
</template>

<script>
import FeedbackAlert from "@/components/feedback-alert.vue";
import { mapState } from "vuex";
import Customer from "@/models/customer";
import CustomerRecap from "@/components/confirm-request/customer-recap.vue";

export default {
  name: "ProfileCustomer",

  components: {
    FeedbackAlert,
    CustomerRecap,
  },

  data() {
    return {
      feedbackMessage: "",
      feedbackStatus: null,
      invalidErrors: {
        invalid: [],
      },
      otherError: { name: null, msg: null },
      customer: Customer,
    };
  },
  computed: {
    ...mapState({
      username: (state) => state.auth.user.username,
      userRole: (state) => state.auth.user.role,
      userType: (state) => state.user.userinfo.type,
      loggedInAsCustomer: (state) => state.customer.status.loggedInAsCustomer,
      loggedIn: (state) => state.auth.status.loggedIn,
      customerInfo: (state) => state.customer.customerinfo,
      customerCode: (state) => state.customer.customerinfo.customerCode,
    }),
    isUserAgentOrCommercial() {
      return this.$store.getters.getUserRole == "AGENT" || this.$store.getters.getUserRole == "COMMERCIAL"
    },
    editable() {
      return this.$store.getters.getEditable;
    },
  },
  methods: {
    toEdit() {
      let args = [];
      let dispatch = "customerMyself";
      if (this.userRole == 'COMMERCIAL') {
        dispatch = "customerCommercial";
        args = [this.customerCode];
      } else if (this.userRole == 'AGENT') {
        dispatch = "customerAgent";
        args = [this.customerCode];
      }
      this.$store
          .dispatch(dispatch, ...args)
          .then((u) => {
            this.customer = u;
            console.log(u);
            this.$router.push("profile-customer/edit-request");
          })
          .catch((e) => console.error(e));
      //check modicfiche
    },
    showModal() {
      this.$refs.notificationMailSubscription.show();
    },
    hideModal() {
      this.$refs.notificationMailSubscription.hide();
    },

    clearStatus() {
      this.feedbackStatus = null;
      this.feedbackMessage = "";
    },
  },
  created() {
    if (
        this.loggedIn &&
        !this.loggedInAsCustomer &&
        !this.isUserAgentOrCommercial
    ) {
      this.$store
          .dispatch("customerMyself")
          .then((u) => {
            this.customer = u;
            console.log(u);
            console.log("User loaded created");
          })
          .catch((e) => console.error(e));
    } else {
      this.customer = this.customerInfo;
      console.log(this.customer);
    }
  },
  watch: {
    loggedIn(logged, old) {
      if (old == false && logged == true) {
        console.log(this.customer);
        this.$store
            .dispatch("customerMyself")
            .then((u) => {
              this.customer = u;
              console.log(u);
            })
            .catch((e) => console.error(e));
      }
    },
  },
  mounted() {
    this.customer = this.customerInfo;
    let dispatch = "checkCustomerEdit";
    if (this.$store.getters.getUserRole == "COMMERCIAL") {
      dispatch = "checkCustomerEditCommercial";
    } else if (this.$store.getters.getUserRole == "AGENT") {
      dispatch = "checkCustomerEditAgent";
    }
    this.$store.dispatch(dispatch, this.customerCode);
    console.log(dispatch);
  },
};
</script>
