<template>
  <div>

    <feedback-alert
        class="w-100"
        :status="feedbackStatus"
        :message="feedbackMessage"
        @clearStatus="clearStatus"
        @back="exit('/profile-customer')"/>

    <b-list-group v-if="feedbackStatus !== 'Success'">
      <b-list-group-item>
        <form-client-anagraphics
            :ref="'Anagraphics'"
            :form="form"
            :user="user"
            :edit="true"
            :invalidErrors="validation.invalid"/>
      </b-list-group-item>

      <b-list-group-item>
        <form-client-addresses
            :ref="'Addresses'"
            class="mb-3"
            :user="user"
            :shippingAddresses.sync="form.shippingAddresses"
            :legalAddress.sync="form.legalAddress"
            :businessAddress.sync="form.businessAddress"
            :invalidErrors="validation"/>
      </b-list-group-item>

      <b-list-group-item v-if="userRole === 'CUSTOMER'">
        <form-client-contacts
            :ref="'Contacts'"
            :form.sync="form"
            :invalidErrors="validation"
            :user="user"
            :isMounted="isMounted"/>
      </b-list-group-item>

      <b-list-group-item v-if="isUserAgentOrCommercial">
        <form-client-contacts-agent
            :ref="'ContactsAgent'"
            :form.sync="form"
            :additionalContacts.sync="form.additionalContacts"
            :invalidErrors="validation"
            :user="user"
            :isMounted="isMounted"/>
      </b-list-group-item>

      <b-list-group-item>
        <form-client-bank-information
            :ref="'BankInformation'"
            :form="form"
            :invalidErrors="validation.invalid"/>
      </b-list-group-item>

      <b-list-group-item>
        <b-button @click="complete">{{ $t("actions.confirm") }}</b-button>
      </b-list-group-item>
    </b-list-group>

    <b-modal
        ref="customerRequestExistingVat"
        id="customerRequestExistingVat"
        centered
        ok-only
        @ok="$router.push('/profile-customer')"
        :ok-title="$t('actions.goToProfile')"
        :cancel-title="$t('actions.back')">
      <p class="my-4" id="customerRequestExistingVatText">
        {{ $t("alert.editRequestExistingVat") }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import Validator from "../../services/validation-service/validator-service";
import ErrorManager from "../../services/errors-service/errors-manager";
import {mapState} from "vuex";

import {getCustomerForm, sortShippingAdddresses,} from "@/models/customer";
import formClientAnagraphics from "../../components/form-client-anagraphics.vue";
import FormClientCompanyType from "../../components/form-client-companyType.vue";
import FormClientContacts from "../../components/form-client-contacts.vue";
import FormClientContactsAgent from "../../components/form-client-contacts-agent.vue";
import FormClientAddresses from "../../components/form-client-addresses.vue";
import FormClientBankInformation from "../../components/form-client-bankInformation.vue";
import FeedbackAlert from "../../components/feedback-alert.vue";

export default {
  name: "EditCustomer",

  components: {
    formClientAnagraphics,
    FormClientCompanyType,
    FormClientContacts,
    FormClientContactsAgent,
    FormClientAddresses,
    FormClientBankInformation,
    FeedbackAlert,
  },

  data() {
    return {
      isMounted: false,
      step: 0,
      baseStep: {
        name: "",
        position: 0,
        fields: [],
        form: {},
      },
      validation: {
        invalid: [],
      },
      feedbackStatus: null,
      feedbackMessage: "",
    };
  },

  computed: {
    ...mapState({
      requestStatus: (state) => state.request.request.status,
      request: (state) => state.request.request.info,
      loggedInPrivateArea: (state) => state.user.status.loggedInPrivateArea,
      loggedIn: (state) => state.auth.status.loggedIn,
      username: (state) => state.auth.user.username,
      userRole: (state) => state.auth.user.role,
      userType: (state) => state.user.userinfo.type,
      loggedInAsCustomer: (state) => state.customer.status.loggedInAsCustomer,
      loggedIn: (state) => state.auth.status.loggedIn,
      customerInfo: (state) => state.customer.customerinfo,
      user: (state) => state.user.userinfo,
    }),

    isUserAgentOrCommercial() {
      return this.$store.getters.getUserRole === "AGENT" || this.$store.getters.getUserRole === "COMMERCIAL"
    },

    form: {
      get() {
        let agent = this.isUserAgentOrCommercial;
        let empty = false;
        let formC = getCustomerForm(
            empty,
            this.$store.getters.getCustomerInfo,
            agent
        );
        console.log(formC);
        return formC;
      },
      set(form) {
        let agent = this.isUserAgentOrCommercial;
        let empty = false;
        let formC = getCustomerForm(empty, form, agent);

        return formC;
      },
    },
  },

  methods: {
    showModal() {
      this.$refs.customerRequestExistingVat.show();
    },

    validationState(contextRef) {
      return this.invalidErrors.find(
          (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
      )
          ? false
          : null;
    },
    validationError(contextRef) {
      if (this.invalidErrors.length > 0) {
        let find =
            this.invalidErrors.find(
                (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
            ) || null;
        return find ? this.$i18n.t(find.error) : null;
      }
    },

    clearStatus() {
      this.feedbackStatus = null;
      this.feedbackMessage = "";
    },
    getExtravalues() {
      let contactsRefName =
          this.isUserAgentOrCommercial
              ? "ContactsAgent"
              : "Contacts";
      console.log(this.$refs[contactsRefName]);
      let contactsRef = this.$refs[contactsRefName];
      let extravalues = {
        businessAddress: {
          zipOrPostalCode: {
            name: "country",
            value: this.form.businessAddress.country,
          },
        },
        legalAddress: {
          zipOrPostalCode: {
            name: "country",
            value: this.form.legalAddress.country,
          },
        },
        shippingAddresses: {},
        purchasingContact: {
          phoneNumber: {
            name: "telephonePrefix",
            value: contactsRef.purchasingContactTelephone,
          },
        },
        administrativeContact: {
          phoneNumber: {
            name: "telephonePrefix",
            value: contactsRef.administrativeContactTelephone,
          },
        },
      };
      this.form.shippingAddresses.forEach((ad, index) => {
        extravalues.shippingAddresses[index] = {
          zipOrPostalCode: {
            name: "country",
            value: this.form.shippingAddresses[index].country,
          },
        };
      });
      if (this.isUserAgentOrCommercial) {
        extravalues = {...extravalues, ...{additionalContacts: {}}};
        this.form.additionalContacts.forEach((ad, index) => {
          console.log(
              "INDEX",
              this.$refs.ContactsAgent.additionalContactsTelephones[index]
          );
          extravalues.additionalContacts[index] = {
            phoneNumber: {
              name: "telephonePrefix",
              value:
                  this.$refs.ContactsAgent.additionalContactsTelephones[index],
            },
          };
        });
      }

      return extravalues;
    },
    //emitted if currentStep.name== name
    draft(exit, end) {
      this.form.shippingAddresses = sortShippingAdddresses(
          this.form.shippingAddresses
      );
      console.log(this.currentForm());
      if (this.validateCurretStep()) {
        let dispatch = "updateCustomerRequest";
        if (this.$store.getters.getUserRole == "COMMERCIAL") {
          dispatch = "commercialUpdateCustomerRequest";
        } else if (this.$store.getters.getUserRole == "AGENT") {
          dispatch = "agentUpdateCustomerRequest";
        }
        this.post(dispatch, exit, end);
      } else {
        console.log("Invalid Form");
      }
    },
    //emitted if currentStep.name== name

    validateCurretForm() {
      this.validation = Validator.validateForm(
          "ConfirmRequest",
          this.form,
          this.getExtravalues()
      );
      console.log(this.validation);
      if (
          this.validation.invalid.length > 0 ||
          Object.keys(this.form).find((f) => {
            return this.validation[f] ? this.checkValidation(f) : false;
          })
      ) {
        return false;
      } else {
        return true;
      }
    },
    checkValidation(invalidEl) {
      if (Array.isArray(this.validation[invalidEl])) {
        console.log(
            "array",
            this.validation[invalidEl].find((el) => {
              return el.invalid.length > 0;
            })
        );
        return this.validation[invalidEl].find((el) => {
          return el.invalid.length > 0;
        });
      } else {
        return this.validation[invalidEl].invalid
            ? this.validation[invalidEl].invalid.length > 0
            : null;
      }
    },

    exit(exit) {
      this.$router.push(exit);
    },

    complete() {
      this.form.shippingAddresses = sortShippingAdddresses(
          this.form.shippingAddresses
      );
      if (this.validateCurretForm()) {
        let dispatch = "postEditRequest";
        if (this.$store.getters.getUserRole == "AGENT") {
          dispatch = "postEditRequestAgent";
        } else if (this.$store.getters.getUserRole == "COMMERCIAL") {
          dispatch = "postEditRequestCommercial";
        }
        this.post(dispatch);
      } else {
        console.log("Invalid Form");
      }
    },
    async post(dispatch) {
      console.log("POST FORM ", this.form);
      await this.$store
          .dispatch(dispatch, this.form)
          .then(() => {
            this.feedbackStatus = "Success";
            this.feedbackMessage = "sendRequestSuccess";
          })
          .catch((error) => {
            console.error(error);
            if (error.status === 409) {
              this.showModal();
            } else {
              this.feedbackStatus = "FailDismissable";
              this.feedbackMessage = ErrorManager.feedback(error);
            }
          });
    },
  },

  mounted() {
    this.isMounted = true;
  },
};
</script>

<style scoped>
.list-group-item {
  padding-bottom: 50px;
}
</style>